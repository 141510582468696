import React from "react";
import PropTypes from "prop-types";
import { Button, Modal } from "semantic-ui-react";

const propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired
};

const ContentCachedModal = ({ open, handleClose }) => {
  return (
    <Modal open={open} onClose={handleClose}>
      <Modal.Header>App available offline</Modal.Header>
      <Modal.Content>
        <p>The app can now be used offline.</p>
        <p>Please note that not all of the functions are available offline.</p>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={handleClose}>Got it.</Button>
      </Modal.Actions>
    </Modal>
  );
};

ContentCachedModal.propTypes = propTypes;

export default ContentCachedModal;
