export const RED = "#ff695e";
export const ORANGE = "#ff851b";
export const YELLOW = "#ffe21f";
export const OLIVE = "#d9e778";
export const GREEN = "#22be34";
export const TEAL = "#6dffff";
export const GREY = "#838383";
export const BLACK = "#1b1c1d";

export const ACTIVE_COLOR = "#e03997";
export const INACTIVE_COLOR = "#6435c9";
export const RATED_COLOR = "#c2fb08";

export const COLOR_MAPPING = {
  [RED]: "red",
  [ORANGE]: "orange",
  [YELLOW]: "yellow",
  [OLIVE]: "olive",
  [GREEN]: "green",
  [TEAL]: "teal",
  [GREY]: "grey",
  [BLACK]: "black"
};
