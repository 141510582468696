import React from "react";
import PropTypes from "prop-types";
import { Button, Message, Modal } from "semantic-ui-react";
import localForage from "localforage";

import { APOLLO_CACHE_PERSIST_KEY } from "./../../constants";

const propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired
};

const NewContentModal = ({ open, handleClose }) => {
  return (
    <Modal open={open} onClose={handleClose}>
      <Modal.Header>Update available</Modal.Header>
      <Modal.Content>
        <p>There's an update available for this app.</p>
        <Message color="yellow">
          Close all tabs of this app and load it anew to start using the new
          version.
        </Message>
      </Modal.Content>
      <Modal.Actions>
        <Button
          onClick={async () => {
            // purge cache so new scheme can be used
            await localForage.removeItem(APOLLO_CACHE_PERSIST_KEY);
            handleClose();
          }}
        >
          Got it.
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

NewContentModal.propTypes = propTypes;

export default NewContentModal;
