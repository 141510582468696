import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button, Icon, Popup } from "semantic-ui-react";

import AddOrEditModal from "./AddOrEditModal";
import DeleteConfirmModal from "./DeleteConfirmModal";

const propTypes = {
  activeVine: PropTypes.object.isRequired,
  activeRow: PropTypes.object.isRequired,
};

const VineCRUDModal = ({ activeRow, ratingId }) => {
  const [action, setAction] = useState(null);

  return (
    <React.Fragment>
      <Popup
        content="Edit current vine"
        trigger={
          <Button icon onClick={() => setAction("edit")}>
            <Icon name="pencil alternate" />
          </Button>
        }
      />
      <Popup
        content="Add vine before/after current"
        trigger={
          <Button icon onClick={() => setAction("add")}>
            <Icon name="add" />
          </Button>
        }
      />
      <Popup
        content="Delete current vine"
        trigger={
          <Button icon onClick={() => setAction("delete")}>
            <Icon name="trash alternate" />
          </Button>
        }
      />
      <AddOrEditModal
        open={["edit", "add"].includes(action)}
        handleClose={() => {
          setAction(null);
        }}
        action={action}
        ratingId={ratingId}
      />
      <DeleteConfirmModal
        activeRow={activeRow}
        ratingId={ratingId}
        handleClose={() => setAction(null)}
        open={action === "delete"}
      />
    </React.Fragment>
  );
};

VineCRUDModal.propTypes = propTypes;

export default VineCRUDModal;
