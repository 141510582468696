import _GIT from "./git.json";

export function getTag() {
  const regex = /tag: (\d{1,2}\.\d{1,2}\.\d{1,3})/;
  const match = regex.exec(_GIT.refs);
  if (match && match.length > 1) {
    return match[1];
  }
  return null;
}

export const JWT_TOKEN_KEY = "token";
export const REFRESH_TOKEN_KEY = "refresh_token";

export const CONTENT_CACHED_EVENT = "content-cached";
export const NEW_CONTENT_EVENT = "new-content";
export const REQUEST_NEW_TOKEN_EVENT = "request-new-token";
export const RECEIVED_NEW_TOKEN_EVENT = "received-new-token";

export const APOLLO_CACHE_PERSIST_KEY = "apollo-cache-persist";

export const GRAPHQL_URI = process.env.REACT_APP_GRAPHQL_URI;
export const REST_API_URI = process.env.REACT_APP_REST_API_URI;

export const GIT_COMMIT_SHA = process.env.REACT_APP_VERCEL_GIT_COMMIT_SHA;
