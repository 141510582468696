import { OFFLINE_RATINGS } from "./components/HeaderWrapper/OfflineRatings";
import {
  OFFLINE_CREATED_VINES,
  OFFLINE_EDITED_VINES,
  OFFLINE_DELETED_VINES,
} from "./constants/queries";

import { uuidv4 } from "./helpers/utils";

export const resolvers = {
  Mutation: {
    addOrUpdateOfflineRating: (_root, variables, { cache }) => {
      const prev = cache.readQuery({
        query: OFFLINE_RATINGS,
      });

      const newOfflineRating = variables;
      newOfflineRating.__typename = "OfflineRating";

      // apollo needs an id on the entry... I learned this the hard way.
      // trust me.
      if (!newOfflineRating.id) {
        if (newOfflineRating.vineId) {
          newOfflineRating.id = `${parseInt(Math.random() * 100)}-${
            variables.vine
          }-${variables.row}`;
        } else {
          newOfflineRating.id = `${parseInt(Math.random() * 100)}-${
            variables.polygonId
          }`;
        }
        newOfflineRating.pseudoId = true;
      } else if (
        typeof newOfflineRating.id === "string" &&
        newOfflineRating.id.split("-").length > 1
      ) {
        newOfflineRating.pseudoId = true;
      } else {
        newOfflineRating.pseudoId = false;
      }

      const existingOfflineRatingIndex = prev.offlineRatings.findIndex((r) => {
        return (
          (newOfflineRating.vine &&
            r.vine === newOfflineRating.vine &&
            r.rating === newOfflineRating.rating &&
            r.row === newOfflineRating.row) ||
          (newOfflineRating.polygonId &&
            r.polygonId === newOfflineRating.polygonId)
        );
      });

      let data;
      if (existingOfflineRatingIndex > -1) {
        const existingRating = prev.offlineRatings[existingOfflineRatingIndex];
        const mergedRating = { ...existingRating, ...newOfflineRating };
        mergedRating.__typename = "OfflineRating";
        data = {
          offlineRatings: [
            ...prev.offlineRatings.slice(0, existingOfflineRatingIndex),
            mergedRating,
            ...prev.offlineRatings.slice(existingOfflineRatingIndex + 1),
          ],
        };
      } else {
        data = {
          offlineRatings: [...prev.offlineRatings, newOfflineRating],
        };
      }

      cache.writeData({ data });

      const returnObject = {
        __typename: "rating_vinerating",
        id: newOfflineRating.id,
        comment: newOfflineRating.comment,
        created: new Date().toISOString(),
        trait_traittier: {
          id: newOfflineRating.traitTierId,
          name: newOfflineRating.traitTierName,
          color: newOfflineRating.traitTierColor,
          __typename: "trait_traittier",
        },
        vineyard_vine: null,
        vineyard_polygon: null,
      };
      if (newOfflineRating.vine) {
        returnObject.vineyard_vine = {
          id: newOfflineRating.vineId,
          number: newOfflineRating.vine,
          __typename: "vineyard_vine",
          row: {
            id: newOfflineRating.row,
            __typename: "vineyard_row",
          },
        };
      }
      if (newOfflineRating.polygonId) {
        returnObject.vineyard_polygon = {
          __typename: "vineyard_polygon",
          id: newOfflineRating.polygonId,
          number: newOfflineRating.polygonNumber,
        };
      }
      return returnObject;
    },
    removeOfflineRating: (_root, variables, { cache }) => {
      const prev = cache.readQuery({
        query: OFFLINE_RATINGS,
      });

      const { vineNumber, rowId, polygonId } = variables;

      const ratingIndex = prev.offlineRatings.findIndex((r) => {
        return (
          (vineNumber && r.vine === vineNumber && r.row === rowId) ||
          (polygonId && r.polygonId === polygonId)
        );
      });

      if (ratingIndex > -1) {
        const offlineRatings = prev.offlineRatings;
        offlineRatings.splice(ratingIndex, 1);

        const data = { offlineRatings };

        cache.writeQuery({
          query: OFFLINE_RATINGS,
          data,
        });
        return variables;
      }
    },
    createVineOffline: (_root, variables, { cache }) => {
      const prev = cache.readQuery({
        query: OFFLINE_CREATED_VINES,
      });

      const { newVineNumber, rowId, longitude, latitude } = variables;

      const newVine = {
        __typename: "OfflineVine",
        id: uuidv4(),
        newVineNumber,
        rowId,
        location: {
          longitude,
          latitude,
          __typename: "PointType",
        },
      };

      const data = {
        offlineCreateVines: [...prev.offlineCreateVines, newVine],
      };
      cache.writeData({ data });
      return newVine;
    },
    removeOfflineCreatedVine: (_root, variables, { cache }) => {
      const prev = cache.readQuery({
        query: OFFLINE_CREATED_VINES,
      });

      const { vineNumber, rowId } = variables;
      const vineIndex = prev.offlineCreateVines.findIndex(
        (v) => v.newVineNumber === vineNumber && v.rowId === rowId
      );

      if (vineIndex !== -1) {
        const offlineCreateVines = prev.offlineCreateVines;
        offlineCreateVines.splice(vineIndex, 1);

        const data = { offlineCreateVines };

        cache.writeData({ data });
        return variables;
      }
    },
    editVineOffline: (_root, variables, { cache }) => {
      const prev = cache.readQuery({
        query: OFFLINE_EDITED_VINES,
      });

      const { vineId, vineNumber, rowId, longitude, latitude } = variables;

      const existingIndex = prev.offlineEditVines.findIndex(
        (v) => v.vineNumber === vineNumber && v.rowId === rowId
      );

      let data;
      //   let result;
      if (existingIndex > -1) {
        data = { offlineEditVines: [...prev.offlineEditVines] };
        data.offlineEditVines[existingIndex].location.longitude = longitude;
        data.offlineEditVines[existingIndex].location.latitude = latitude;
        // update the id just to be on the safe side
        data.offlineEditVines[existingIndex].id = uuidv4();
        // result = data.offlineEditVines[existingIndex];
      } else {
        const newVine = {
          __typename: "OfflineEditVine",
          id: uuidv4(),
          vineNumber,
          rowId,
          location: {
            longitude,
            latitude,
            __typename: "PointType",
          },
        };
        data = { offlineEditVines: [...prev.offlineEditVines, newVine] };
        // result = newVine;
      }

      cache.writeData({ data });
      //   return result;
      return {
        __typename: "vineyard_vine",
        id: vineId,
        number: vineNumber,
        location: {
          type: "Point",
          coordinates: [longitude, latitude],
        },
        vineyard_row: {
          __typename: "vineyard_row",
          id: rowId,
        },
      };
    },
    removeOfflineEditedVine: (_root, variables, { cache }) => {
      const prev = cache.readQuery({
        query: OFFLINE_EDITED_VINES,
      });

      const { vineNumber, rowId } = variables;
      const vineIndex = prev.offlineEditVines.findIndex(
        (v) => v.vineNumber === vineNumber && v.rowId === rowId
      );
      if (vineIndex !== -1) {
        const offlineEditVines = prev.offlineEditVines;
        offlineEditVines.splice(vineIndex, 1);

        const data = { offlineEditVines };

        cache.writeData({ data });
        return variables;
      }
    },
    deleteVineOffline: (_root, variables, { cache }) => {
      const prev = cache.readQuery({
        query: OFFLINE_DELETED_VINES,
      });

      const { vineNumber, rowId } = variables;

      const newVine = {
        __typename: "OfflineDeletedVine",
        id: uuidv4(),
        vineNumber,
        rowId,
      };
      const data = {
        offlineDeleteVines: [...prev.offlineDeleteVines, newVine],
      };

      cache.writeData({ data });
      return newVine;
    },

    removeOfflineDeletedVine: (_root, variables, { cache }) => {
      const prev = cache.readQuery({
        query: OFFLINE_DELETED_VINES,
      });
      const { vineNumber, rowId } = variables;
      const vineIndex = prev.offlineDeleteVines.findIndex(
        (v) =>
          parseInt(v.vineNumber) === vineNumber && parseInt(v.rowId) === rowId
      );
      if (vineIndex !== -1) {
        const offlineDeleteVines = prev.offlineDeleteVines;
        offlineDeleteVines.splice(vineIndex, 1);

        const data = { offlineDeleteVines };

        cache.writeData({ data });
        return variables;
      }
    },
  },
};
