import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Link } from "react-router-dom";

const Wrapper = styled.div`
  margin-right: 1rem;
`;

const BackButton = ({ to }) => {
  return (
    <Wrapper>
      <Link to={to}>&larr; go back</Link>
    </Wrapper>
  );
};

const propTypes = {
  to: PropTypes.string.isRequired
};

BackButton.propTypes = propTypes;

export default BackButton;
