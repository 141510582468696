import React from "react";
import { Icon, Menu } from "semantic-ui-react";
import useReactRouter from "use-react-router";

import {
  SETTINGS_VIEW,
  RATINGS_VIEW,
  TRAITS_VIEW
} from "./../../constants/routes";

const MENU_VIEWS = [RATINGS_VIEW, TRAITS_VIEW, SETTINGS_VIEW];

const TheMenu = () => {
  const { history, location } = useReactRouter();
  const handleItemClick = pathname => history.push({ pathname });

  return (
    <Menu icon="labeled" fluid widths={MENU_VIEWS.length} size="mini">
      {MENU_VIEWS.map((view, index) => (
        <Menu.Item
          key={`menu-item-${index}`}
          name={view.pathname}
          active={location.pathname.includes(view.pathname)}
          onClick={() => handleItemClick(view.pathname)}
        >
          <Icon name={view.icon} />
          {view.title}
        </Menu.Item>
      ))}
    </Menu>
  );
};

export default TheMenu;
