import React, { useState } from "react";
import gql from "graphql-tag";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { Loader, Table } from "semantic-ui-react";
import useOnlineStatus from "@rehooks/online-status";
import styled from "styled-components";

import { REST_API_URI } from "./../../../constants";

import EditRatingModal from "./EditRatingModal";
import TableRow from "./TableRow";
import ScrollWrapper from "./../../../components/ScrollWrapper";

const EmptyIndicator = styled.p`
  margin-top: 3rem;
  text-align: center;
`;

export const GET_RATINGS = gql`
  query getRatings {
    viewer {
      user {
        email
        id
      }
      ratings {
        id
        created
        name
        createdBy {
          id
          email
        }
        project {
          id
          name
        }
        traits {
          id
          name
        }
        vineyard {
          id
          name
          flikNumber
        }
      }
    }
  }
`;

const CREATE_SHAPEFILE = gql`
  mutation createShapefile($ratingId: Int!) {
    createShapefile(ratingId: $ratingId) {
      fileUrl
    }
  }
`;

const CREATE_CSV = gql`
  mutation createCsv($ratingId: Int!) {
    createCsv(ratingId: $ratingId) {
      fileUrl
    }
  }
`;

const RatingsTable = () => {
  const [ratingToEdit, setRatingToEdit] = useState(null);
  const [
    isCreatingShapefileForIndex,
    setIsCreatingShapefileForIndex,
  ] = useState(null);
  const online = useOnlineStatus();

  const [createShapefile] = useMutation(CREATE_SHAPEFILE, {
    update: (proxy, result) => {
      try {
        setIsCreatingShapefileForIndex(null);
        const url = result.data.createShapefile.fileUrl;
        window.location = `${REST_API_URI}${url}`;
      } catch (error) {
        console.log(error);
      }
    },
  });

  const [createCsv] = useMutation(CREATE_CSV, {
    update: (proxy, result) => {
      try {
        const url = result.data.createCsv.fileUrl;
        window.location = `${REST_API_URI}${url}`;
      } catch (error) {
        console.log("oh oh oh oh", error);
      }
    },
  });

  const { data, loading } = useQuery(GET_RATINGS, {
    fetchPolicy: online ? "cache-and-network" : "cache-only",
  });

  if (loading) {
    return <Loader active />;
  }
  if (data && data.viewer && !data.viewer.ratings.length) {
    return <EmptyIndicator>No ratings yet.</EmptyIndicator>;
  }
  if (data && data.viewer && data.viewer.ratings.length) {
    return (
      <ScrollWrapper>
        <EditRatingModal
          rating={ratingToEdit}
          handleClose={() => setRatingToEdit(null)}
        />
        <Table sortable>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Name</Table.HeaderCell>
              <Table.HeaderCell>Created on</Table.HeaderCell>
              <Table.HeaderCell>Created by</Table.HeaderCell>
              <Table.HeaderCell>Project</Table.HeaderCell>
              <Table.HeaderCell>Trait</Table.HeaderCell>
              <Table.HeaderCell>Field</Table.HeaderCell>
              <Table.HeaderCell>Offline ready</Table.HeaderCell>
              <Table.HeaderCell>Actions</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {data.viewer.ratings.map((rating, index) => {
              return (
                <TableRow
                  online={online}
                  key={`rating-${index}`}
                  rating={rating}
                  loading={isCreatingShapefileForIndex === index}
                  createShapefile={(arg) => {
                    setIsCreatingShapefileForIndex(index);
                    createShapefile(arg);
                  }}
                  createCsv={createCsv}
                  editRating={setRatingToEdit}
                />
              );
            })}
          </Table.Body>
        </Table>
      </ScrollWrapper>
    );
  }
  return null;
};

export default RatingsTable;
