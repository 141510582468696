import React from "react";

const VineRatingMappingContext = React.createContext();

function VineRatingMappingProvider(props) {
  const [vineRatingMapping, setVineRatingMapping] = React.useState({});
  const [polygonRatingMapping, setPolygonRatingMapping] = React.useState({});

  /**
   *
   * @param {array} vineRatings
   */
  const initializeVineRatingMapping = (activeTraitId, vineRatings) => {
    const mapping = {};
    for (const vineRating of vineRatings) {
      if (vineRating.vineyard_vine) {
        const key = `${activeTraitId}-${vineRating.vineyard_vine.vineyard_row.id}-${vineRating.vineyard_vine.number}`;
        mapping[key] = { ...vineRating };
      }
    }
    setVineRatingMapping(mapping);
  };

  const initializePolygonRatingMapping = (activeTraitId, ratings = []) => {
    const mapping = {};
    for (const rating of ratings) {
      if (rating.vineyard_polygon) {
        const key = `${activeTraitId}-${rating.vineyard_polygon.id}`;
        mapping[key] = { ...rating };
      }
    }
    setPolygonRatingMapping(mapping);
  };

  return (
    <VineRatingMappingContext.Provider
      value={{
        vineRatingMapping,
        setVineRatingMapping,
        polygonRatingMapping,
        setPolygonRatingMapping,
        initializeVineRatingMapping,
        initializePolygonRatingMapping,
      }}
      {...props}
    />
  );
}

const useVineRatingMapping = () => React.useContext(VineRatingMappingContext);

export { VineRatingMappingProvider, useVineRatingMapping };
