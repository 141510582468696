import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Message } from "semantic-ui-react";

const Wrapper = styled.div`
  flex: 1;
  overflow: hidden;
  padding: 0 1rem;
  display: flex;
  flex-direction: column;
`;

const propTypes = {
  children: PropTypes.node
};

class BodyWrapper extends React.Component {
  state = {
    error: false
  };

  componentDidCatch(error) {
    console.log(error);
    this.setState({ error: true });
  }

  render() {
    return (
      <Wrapper id="body-wrapper">
        {!this.state.error ? (
          this.props.children
        ) : (
          <Message color="red">Something went wrong... please reload.</Message>
        )}
      </Wrapper>
    );
  }
}

BodyWrapper.propTypes = propTypes;

export default BodyWrapper;
