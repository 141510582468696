import React, { useState, useEffect, useCallback } from "react";
import styled from "styled-components";
import { Loader } from "semantic-ui-react";

import { useVineRatingMapping } from "../../../context/vineRatingMapping";
import { useActiveRating } from "./../../../context/activeRating";

import RatingFormButtons from "../../../components/RatingFormButtons";
import RowBar from "./RowBar";
import VineyardMap from "./VineyardMap";

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  overflow: hidden;
`;

const FormWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
`;

const MapWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  background-color: #e6e4e0;
`;

const RatingQuery = () => {
  const {
    activeRating,
    loading,
    activeRow,
    setActiveRow,
    activeVine,
    setActiveVine,
    activeTrait,
  } = useActiveRating();

  // vine and row
  const [reverse, setReverse] = useState(false);
  const { setVineRatingMapping, vineRatingMapping } = useVineRatingMapping();

  const rating = activeRating?.rating_rating_by_pk;

  ////////////////////////////////////////////
  // 0. reset active vine when navigating away
  ////////////////////////////////////////////

  useEffect(() => {
    return () => {
      setActiveVine(null);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const updateVineRatingMapping = (rowId, vineNumber, vineRating) => {
    const mapping = { ...vineRatingMapping };
    const key = `${activeTrait.trait_trait.id}-${rowId}-${vineNumber}`;
    mapping[key] = vineRating;
    setVineRatingMapping(mapping);
  };

  //////////////////////////////////////////////////////
  // 3. find and set active row (happens only initially)
  //////////////////////////////////////////////////////
  const findAndSetActiveRow = useCallback(() => {
    if (activeRow === null) {
      if (rating) {
        const row = rating.vineyard_vineyard.vineyard_rows[0];
        setActiveRow(row);
        const theActiveVine = row.vineyard_vines[0];
        setActiveVine(theActiveVine);
      }
    }
  }, [rating, activeRow, setActiveRow, setActiveVine]);

  useEffect(() => {
    findAndSetActiveRow();
  }, [rating, findAndSetActiveRow, activeRow]);

  //////////////////////////////
  // 4. find and set active vine
  //////////////////////////////
  const findAndSetActiveVine = useCallback(() => {
    if (rating) {
      let theActiveVine;
      if (activeRow && !reverse) {
        theActiveVine = activeRow.vineyard_vines[0];
      } else if (activeRow && reverse) {
        theActiveVine =
          activeRow.vineyard_vines[activeRow.vineyard_vines.length - 1];
      }
      if (theActiveVine) {
        setActiveVine(theActiveVine);
      }
    }
  }, [activeRow, reverse, setActiveVine]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    findAndSetActiveVine();
  }, [activeRow, findAndSetActiveVine]);

  /**
   * actively triggered from within the rating form
   * @param {bool} incrementActiveVine
   */
  const afterVineRated = (vineNumber, vineRating, incrementActiveVine) => {
    updateVineRatingMapping(activeRow.id, vineNumber, vineRating);
    if (!incrementActiveVine) {
      setActiveVine(activeVine);
      return;
      // or just return and do nothing?
    }
    const activeVineIndex = activeRow.vineyard_vines.findIndex(
      (v) => v.id === activeVine.id
    );
    if (
      !reverse &&
      activeRow.vineyard_vines.length - 1 >= activeVineIndex + 1
    ) {
      setActiveVine(activeRow.vineyard_vines[activeVineIndex + 1]);
    } else if (reverse && activeVineIndex > 0) {
      setActiveVine(activeRow.vineyard_vines[activeVineIndex - 1]);
    }
  };

  const showLoader = !activeVine || !activeRow || loading;

  return (
    <React.Fragment>
      <Wrapper>
        <FormWrapper>
          {showLoader ? (
            <Loader active />
          ) : (
            <RatingFormButtons handleAfterVineRated={afterVineRated} />
          )}
        </FormWrapper>
        <MapWrapper>
          {showLoader ? (
            <Loader active />
          ) : (
            <VineyardMap
              ratingId={rating.id}
              activeRow={activeRow}
              activeVine={activeVine}
            />
          )}
        </MapWrapper>
      </Wrapper>
      {!showLoader ? (
        <RowBar
          activeVineNumber={activeVine.number}
          rows={rating.vineyard_vineyard.vineyard_rows}
          handleSetActiveVine={setActiveVine}
          reverse={reverse}
          handleReverse={setReverse}
        />
      ) : null}
    </React.Fragment>
  );
};

export default RatingQuery;
