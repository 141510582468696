import React from "react";
import PropTypes from "prop-types";
import { Table, Icon, Dropdown, Button } from "semantic-ui-react";
import gql from "graphql-tag";
import useReactRouter from "use-react-router";
import { useQuery } from "@apollo/react-hooks";
import { RATINGS_VIEW } from "./../../../../constants/routes";

const propTypes = {
  rating: PropTypes.object.isRequired,
  online: PropTypes.bool.isRequired,
  createShapefile: PropTypes.func.isRequired,
  createCsv: PropTypes.func.isRequired,
  editRating: PropTypes.func.isRequired,
  loading: PropTypes.bool,
};

const RATING_CACHED = gql`
  query ratingCached($id: Int!) {
    rating_rating_by_pk(id: $id) {
      id
    }
  }
`;

const TableRow = ({
  rating,
  online,
  createShapefile,
  createCsv,
  editRating,
  loading,
}) => {
  const { history } = useReactRouter();
  const created = new Date(rating.created);
  const { data } = useQuery(RATING_CACHED, {
    variables: { id: rating.id },
    fetchPolicy: "cache-only",
  });

  const offlineAvailable =
    data && data.rating_rating_by_pk && data.rating_rating_by_pk.id;

  const handleAction = (id, action) => {
    if (action === "rate") {
      // navigate to DoRateView
      history.push({ pathname: `${RATINGS_VIEW.pathname}/${id}/rate` });
      return;
    }
    if (action === "edit") {
      console.log("action: edit");
      editRating(rating);
      return;
    }
    if (action === "shapefile") {
      // generate and download shapefile
      createShapefile({ variables: { ratingId: id } });
      return;
    }
    if (action === "csv") {
      // generate and download shapefile
      createCsv({ variables: { ratingId: id } });
      return;
    }
  };

  return (
    <Table.Row>
      <Table.Cell>{rating.name || "-"}</Table.Cell>
      <Table.Cell>{created.toLocaleDateString()}</Table.Cell>
      <Table.Cell>{rating.createdBy ? rating.createdBy.email : "-"}</Table.Cell>
      <Table.Cell>{rating.project.name}</Table.Cell>
      <Table.Cell>{rating.traits.map((t) => t.name).join(", ")}</Table.Cell>
      <Table.Cell>
        {rating.vineyard.name} ({rating.vineyard.flikNumber})
      </Table.Cell>
      <Table.Cell>
        {offlineAvailable ? (
          <Icon color="green" name="check circle" />
        ) : (
          <Icon color="grey" name="times circle" />
        )}
      </Table.Cell>
      <Table.Cell>
        <Dropdown
          direction="left"
          loading={loading}
          inline
          basic
          trigger={<Button circular icon="angle down" />}
          icon=""
          onChange={(evt, data) => {
            handleAction(rating.id, data.value);
          }}
          options={[
            {
              value: "rate",
              text: "Rate",
              disabled: !online && !offlineAvailable,
            },
            {
              value: "edit",
              text: "Edit",
              disabled: !online,
            },
            {
              value: "shapefile",
              text: "Download Shapefile",
              disabled: !online || loading,
            },
            {
              value: "csv",
              text: "Download CSV",
              disabled: !online,
            },
          ]}
        />
      </Table.Cell>
    </Table.Row>
  );
};

TableRow.propTypes = propTypes;

export default TableRow;
