import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Button } from "semantic-ui-react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import gql from "graphql-tag";
import { useMutation } from "@apollo/react-hooks";

import ScrollWrapper from "./../../../components/ScrollWrapper";

import EditTraitTierModal from "./EditTraitTierModal";

import { TRAIT } from "./../index";

const Wrapper = styled.div`
  margin-top: 1rem;
`;

const TierWrapper = styled.div`
  margin-bottom: 1rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  background: ${(props) => (props.isDragging ? "#9ccad7" : props.color)};
  text-align: center;
  position: relative;
`;

const EditButtonWrapper = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const UPDATE_TRAIT_TIER_ORDER = gql`
  mutation updateTraitTier($input: OrderUpdateInput!) {
    updateTraitTierOrder(input: $input) {
      trait {
        id
        name
        traitTiers {
          id
          name
          order
          color
        }
      }
    }
  }
`;

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

// TODO: optimistic UI

// TODO: Tier as own component
const TraitTierList = ({ traitId, traitTiers }) => {
  const [traitTierToEdit, setTraitTierToEdit] = useState(null);
  const [updateTraitTier] = useMutation(UPDATE_TRAIT_TIER_ORDER, {
    update: (cache, { data: { updateTraitTierOrder } }) => {
      const { viewer } = cache.readQuery({
        query: TRAIT,
        variables: { id: traitId },
      });

      viewer.trait.traitTiers = updateTraitTierOrder.trait.traitTiers;

      cache.writeQuery({
        query: TRAIT,
        data: { viewer },
        variables: { id: traitId },
      });
    },
  });

  const openEditModal = (traitTier) => {
    setTraitTierToEdit(traitTier);
  };

  return (
    <React.Fragment>
      <EditTraitTierModal
        open={traitTierToEdit !== null}
        handleClose={() => setTraitTierToEdit(null)}
        traitTier={traitTierToEdit}
        traitId={traitId}
      />
      <h3>Trait Tiers</h3>
      <DragDropContext
        onDragEnd={(result) => {
          const reorderedTraitTiers = reorder(
            traitTiers,
            result.source.index,
            result.destination.index
          );
          const newOrder = reorderedTraitTiers.map((tier, index) => ({
            order: index,
            id: tier.id,
          }));
          updateTraitTier({
            variables: { input: { trait: traitId, newOrder } },
          });
        }}
      >
        <ScrollWrapper>
          <Droppable droppableId="droppable">
            {(provided) => (
              <Wrapper {...provided.droppableProps} ref={provided.innerRef}>
                {traitTiers.map((tier, index) => (
                  <Draggable key={tier.id} draggableId={tier.id} index={index}>
                    {(provided, snapshot) => (
                      <TierWrapper
                        isDragging={snapshot.isDragging}
                        color={tier.color}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <div>
                          {tier.name}
                          <EditButtonWrapper>
                            <Button onClick={() => openEditModal(tier)}>
                              edit
                            </Button>
                          </EditButtonWrapper>
                        </div>
                      </TierWrapper>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </Wrapper>
            )}
          </Droppable>
        </ScrollWrapper>
      </DragDropContext>
    </React.Fragment>
  );
};

const propTypes = {
  traitId: PropTypes.number.isRequired,
  traitTiers: PropTypes.array.isRequired,
};

TraitTierList.propTypes = propTypes;

export default TraitTierList;
