import React from "react";
import { Button, Header, Loader, Segment } from "semantic-ui-react";
import gql from "graphql-tag";
import { useApolloClient, useQuery, useMutation } from "@apollo/react-hooks";
import useOnlineStatus from "@rehooks/online-status";
import localForage from "localforage";

import { getTag, GIT_COMMIT_SHA } from "./../../constants";
import HeaderWrapper from "./../../components/HeaderWrapper";
import BodyWrapper from "./../../components/BodyWrapper";

import {
  APOLLO_CACHE_PERSIST_KEY,
  REFRESH_TOKEN_KEY,
  JWT_TOKEN_KEY,
} from "./../../constants";

const GET_USER = gql`
  {
    viewer {
      user {
        email
      }
    }
  }
`;

const LOG_OUT = gql`
  mutation logOut($refreshToken: String!) {
    revokeToken(refreshToken: $refreshToken) {
      revoked
    }
  }
`;

const SettingsView = () => {
  const online = useOnlineStatus();
  const client = useApolloClient();
  const refreshToken = localStorage.getItem(REFRESH_TOKEN_KEY);
  const { data, loading } = useQuery(GET_USER);
  const [logOut] = useMutation(LOG_OUT, {
    onCompleted: () => {
      client.writeData({
        data: { isAuthenticated: false },
      });
      localStorage.removeItem(REFRESH_TOKEN_KEY);
      localStorage.removeItem(JWT_TOKEN_KEY);
    },
  });

  const tag = getTag();

  return (
    <React.Fragment>
      <HeaderWrapper header="Settings" />

      <BodyWrapper>
        {loading || !data || !data.viewer ? (
          <Loader active />
        ) : (
          <p>
            Logged in as <strong>{data.viewer.user.email}</strong>
          </p>
        )}
        <div>
          <Button
            onClick={() => logOut({ variables: { refreshToken } })}
            disabled={loading || !online}
            loading={loading}
          >
            Logout
          </Button>
        </div>
        <Segment>
          <Header as="h3">Info</Header>
          {GIT_COMMIT_SHA ? (
            <div>
              <p>
                The latest commit hash is <code>{GIT_COMMIT_SHA}</code>. You can
                just ignore this.
              </p>
            </div>
          ) : null}
          {tag ? (
            <div>
              <p>You're using version {tag} of this software.</p>
            </div>
          ) : null}
        </Segment>
        <Segment>
          <Header as="h3">Danger Zone</Header>
          <div>
            <p>Delete all cached data from browser.</p>
            <Button
              color="red"
              disabled={!online}
              onClick={async () => {
                await localForage.removeItem(APOLLO_CACHE_PERSIST_KEY);
                window.location.reload();
              }}
            >
              purge data cache
            </Button>
          </div>
        </Segment>
      </BodyWrapper>
    </React.Fragment>
  );
};

export default SettingsView;
