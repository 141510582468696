import React from "react";
import PropTypes from "prop-types";

import { Button } from "semantic-ui-react";
import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  top: 10rem;
  right: 1rem;
`;

const BeforeAfterSelector = ({ handleChange, addPosition }) => {
  return (
    <Wrapper>
      <Button.Group>
        <Button
          active={addPosition === "before"}
          onClick={() => handleChange("before")}
          primary
        >
          before
        </Button>
        <Button
          active={addPosition === "after"}
          onClick={() => handleChange("after")}
          primary
        >
          after
        </Button>
      </Button.Group>
    </Wrapper>
  );
};

BeforeAfterSelector.propTypes = {
  handleChange: PropTypes.func.isRequired,
  addPosition: PropTypes.string.isRequired
};

export default BeforeAfterSelector;
