import React, { useState } from "react";
import PropTypes from "prop-types";
import ReactMapboxGl, { GeoJSONLayer } from "react-mapbox-gl";
import BackgroundGeojson from "./BackgroundGeojson";

const Map = ReactMapboxGl({
  accessToken:
    "pk.eyJ1IjoiY3JlaW1lcnMiLCJhIjoiY2p4cHZjZDdhMGs3ZzNvcDlxdXhzNzJwbyJ9.bz6je21hT4R6nL6LNT4dKQ",
});

const TheMap = ({ center, movingVine, action, activeRow, ratingId }) => {
  const [zoom, setZoom] = useState(23);
  return (
    <Map
      style="mapbox://styles/mapbox/streets-v9" // eslint-disable-line react/style-prop-object
      containerStyle={{
        height: `400px`,
      }}
      center={center}
      zoom={[zoom]}
      onZoomEnd={(map, e) => {
        setZoom(map.transform._zoom);
        // HACK
        map.transform.maxZoom = 23;
      }}
    >
      {/* <RasterLayer ratingId={ratingId} /> */}
      <BackgroundGeojson
        action={action}
        activeRow={activeRow}
        ratingId={ratingId}
      />
      <GeoJSONLayer
        data={movingVine}
        fillPaint={{
          "fill-color": ["get", "color"],
        }}
        circlePaint={{
          "circle-color": ["get", "circleColor"],
          "circle-radius": ["get", "circleRadius"],
        }}
        linePaint={{
          "line-color": ["get", "lineColor"],
          "line-width": 2,
        }}
      />
    </Map>
  );
};

const propTypes = {
  center: PropTypes.arrayOf(PropTypes.number).isRequired,
  movingVine: PropTypes.object,
  action: PropTypes.string,
};

TheMap.propTypes = propTypes;

export default TheMap;
