import jwt_decode from "jwt-decode";

export const isTokenStillValid = token => {
  if (!token) {
    return false;
  }
  try {
    const decoded = jwt_decode(token);
    if (!decoded || !decoded.exp) {
      return false;
    }
    const exp = decoded.exp * 1000;
    if (exp < Date.now()) {
      return false;
    } else if (exp > Date.now()) {
      return true;
    }
  } catch (error) {
    return false;
  }
};

export const isAuthenticated = () => {
  const token = localStorage.getItem("token");
  const stillValid = isTokenStillValid(token);
  return stillValid;
};
