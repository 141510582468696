import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import gql from "graphql-tag";
import styled from "styled-components";
import Div100vh from "react-div-100vh";
import { useQuery, useApolloClient } from "@apollo/react-hooks";
import useOnlineStatus from "@rehooks/online-status";
import { Message, Responsive } from "semantic-ui-react";

import BrowserDetector from "./../BrowserDetector";
import ContentCachedModal from "./../ContentCachedModal";
import FullPageLoader from "./../FullPageLoader";
import Menu from "./../Menu";
import NewContentModal from "./../NewContentModal";
import OfflineHint from "./../OfflineHint";
import PrivateRoute from "../PrivateRoute";

import {
  CONTENT_CACHED_EVENT,
  NEW_CONTENT_EVENT,
  JWT_TOKEN_KEY,
  REFRESH_TOKEN_KEY,
  REQUEST_NEW_TOKEN_EVENT,
  RECEIVED_NEW_TOKEN_EVENT,
  REST_API_URI,
} from "./../../constants";
import { ROUTES } from "../../constants/routes";
import { useInterval } from "./../../helpers/utils";

const receivedNewToken = new Event(RECEIVED_NEW_TOKEN_EVENT);

const AUTH_CHECK = gql`
  {
    isAuthenticated @client
  }
`;

const AppWrapper = styled.div`
  height: 100%;
  max-height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const ViewWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const MessageWrapper = styled.div`
  margin: 1rem;
`;

function App() {
  const online = useOnlineStatus();
  const [isCheckingToken, setIsCheckingToken] = useState(true);
  const [showContentCachedModal, setShowContentCachedModal] = useState(false);
  const [showNewContentModal, setShowNewContentModal] = useState(false);

  const client = useApolloClient();

  const { data } = useQuery(AUTH_CHECK);

  const refreshToken = async (token) => {
    const url = `${REST_API_URI}/refresh-token/`;
    const response = await fetch(url, {
      method: "POST",
      body: JSON.stringify({ refresh_token: token }),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
    const responseData = await response.json();
    localStorage.setItem(JWT_TOKEN_KEY, responseData.token);
    localStorage.setItem(REFRESH_TOKEN_KEY, responseData.refreshToken);
    setIsCheckingToken(false);
    client.writeData({
      data: { isAuthenticated: true },
    });
  };
  const refreshAuthTokens = async () => {
    if (online) {
      const theRefreshToken = localStorage.getItem(REFRESH_TOKEN_KEY);
      if (theRefreshToken) {
        try {
          await refreshToken(theRefreshToken);
          window.dispatchEvent(receivedNewToken);
          setIsCheckingToken(false);
        } catch (error) {
          console.log("error", error);
          setIsCheckingToken(false);

          // if there was an error, we might as well stop refreshing the token
          // by removing it from local storage
          localStorage.removeItem(REFRESH_TOKEN_KEY);

          client.writeData({
            data: { isAuthenticated: false },
          });
        }
      } else {
        setIsCheckingToken(false);

        client.writeData({
          data: { isAuthenticated: false },
        });
      }
    } else {
      // when offline, we assume that a user is authenticated
      setIsCheckingToken(false);
      client.writeData({
        data: { isAuthenticated: true },
      });
    }
  };

  // refresh the token with refresh_token
  useEffect(() => {
    async function refresh() {
      await refreshAuthTokens();
    }
    refresh();
  }, [online]); // eslint-disable-line react-hooks/exhaustive-deps

  // periodically refresh token
  useInterval(refreshAuthTokens, 1000 * 60);

  useEffect(() => {
    window.addEventListener(NEW_CONTENT_EVENT, () =>
      setShowNewContentModal(true)
    );
    window.addEventListener(CONTENT_CACHED_EVENT, () =>
      setShowContentCachedModal(true)
    );
    window.addEventListener(REQUEST_NEW_TOKEN_EVENT, refreshAuthTokens);
    return () => {
      window.removeEventListener(NEW_CONTENT_EVENT, null);
      window.removeEventListener(CONTENT_CACHED_EVENT, null);
      window.removeEventListener(REQUEST_NEW_TOKEN_EVENT, null);
    };
  });

  return (
    <React.Fragment>
      {(isCheckingToken || !client) && online ? (
        <FullPageLoader />
      ) : (
        <React.Fragment>
          <Responsive {...Responsive.onlyMobile}>
            <MessageWrapper>
              <Message color="yellow">
                Please use a tablet or a PC to use this app.
              </Message>
              <Message
                icon="undo"
                content="If you're sure you're holding a tablet in your hand, make sure it's in landscape mode."
              ></Message>
            </MessageWrapper>
          </Responsive>
          <Responsive minWidth={Responsive.onlyTablet.minWidth}>
            <Div100vh>
              <AppWrapper>
                <NewContentModal
                  open={showNewContentModal}
                  handleClose={() => setShowNewContentModal(false)}
                />
                <ContentCachedModal
                  open={showContentCachedModal}
                  handleClose={() => setShowContentCachedModal(false)}
                />
                {!online ? <OfflineHint /> : null}
                <Router>
                  <ViewWrapper>
                    {ROUTES.map((route, index) => {
                      if (route.private) {
                        return (
                          <PrivateRoute
                            key={`private-route-${index}`}
                            path={route.pathname}
                            exact
                            component={route.component}
                          />
                        );
                      }
                      return (
                        <Route
                          key={`route-${index}`}
                          path={route.pathname}
                          exact
                          component={route.component}
                        />
                      );
                    })}
                  </ViewWrapper>
                  {data.isAuthenticated ? <Menu /> : null}
                </Router>
              </AppWrapper>
            </Div100vh>
          </Responsive>
        </React.Fragment>
      )}
      <BrowserDetector />
    </React.Fragment>
  );
}

export default App;
