import React, { useState } from "react";
import gql from "graphql-tag";
import { useMutation } from "@apollo/react-hooks";
import { Button, Form, Modal } from "semantic-ui-react";
import { Formik, Form as FormikForm } from "formik";
import PropTypes from "prop-types";
import styled from "styled-components";

import { TRAIT } from "./../index";

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 1rem;
`;

const ADD_TRAIT = gql`
  mutation addTraitTier($input: CreateOrUpdateTraitTierMutationInput!) {
    createOrUpdateTraitTier(input: $input) {
      id
      name
    }
  }
`;

const NewTraitTierModal = ({ traitId, traitTierNames }) => {
  const [showModal, setShowModal] = useState(false);
  const [createOrUpdateTraitTier, { error }] = useMutation(ADD_TRAIT, {
    // onCompleted: () => setShowModal(false),
    update: (cache, { data: { createOrUpdateTraitTier } }) => {
      // TODO: update also the traits query
      const { viewer } = cache.readQuery({
        query: TRAIT,
        variables: { id: traitId },
      });

      viewer.trait.traitTiers = viewer.trait.traitTiers.concat([
        {
          id: `${createOrUpdateTraitTier.id}`,
          order: viewer.trait.traitTiers.length,
          name: createOrUpdateTraitTier.name,
          __typename: "TraitTierType",
        },
      ]);
      console.log(viewer);
      cache.writeQuery({
        query: TRAIT,
        data: { viewer },
        variables: { id: traitId },
      });
    },
    refetchQueries: () => {
      return ["Trait"];
    },
  });

  return (
    <Modal
      trigger={
        <Button color="green" onClick={() => setShowModal(true)}>
          add class
        </Button>
      }
      open={showModal}
      onClose={() => setShowModal(false)}
    >
      <Modal.Header>Add class</Modal.Header>
      <Modal.Content>
        <Formik
          initialValues={{ name: "" }}
          onSubmit={(values, { setSubmitting }) => {
            createOrUpdateTraitTier({
              variables: { input: { name: values.name, trait: traitId } },
            });
          }}
          validate={(values) => {
            // TODO: use traitTierNames to ensure no duplicates
          }}
        >
          {({ values, handleChange, handleSubmit, isSubmitting }) => (
            <FormikForm onSubmit={handleSubmit} className="ui large form">
              <Form.Input
                placeholder="Name"
                name="name"
                value={values.name}
                onChange={handleChange}
                fluid
                focus={true}
              />
              <ButtonWrapper>
                <Button onClick={() => setShowModal(false)}>cancel</Button>
                <Button
                  disabled={(isSubmitting && !error) || !values.name}
                  loading={isSubmitting && !error}
                  primary
                  type="submit"
                >
                  save
                </Button>
              </ButtonWrapper>
            </FormikForm>
          )}
        </Formik>
      </Modal.Content>
    </Modal>
  );
};

const propTypes = {
  traitId: PropTypes.number.isRequired,
  traitTierNames: PropTypes.arrayOf(PropTypes.string).isRequired,
};

NewTraitTierModal.propTypes = propTypes;

export default NewTraitTierModal;
