import React from "react";
import { Route, Redirect } from "react-router-dom";
import useOnlineStatus from "@rehooks/online-status";
import gql from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";

// import { isTokenStillValid } from "./../../helpers/auth";
// import { JWT_TOKEN_KEY } from "./../../constants";
import { LOGIN_VIEW } from "./../../constants/routes";

const AUTH_CHECK = gql`
  {
    isAuthenticated @client
  }
`;

const PrivateRoute = ({ component: Component, ...rest }) => {
  // const token = localStorage.getItem(JWT_TOKEN_KEY);
  // const stillValid = isTokenStillValid(token);
  const online = useOnlineStatus();
  const { data } = useQuery(AUTH_CHECK);

  return (
    <Route
      {...rest}
      render={props =>
        data.isAuthenticated || !online ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: LOGIN_VIEW.pathname,
              state: { from: props.location }
            }}
          />
        )
      }
    />
  );
};

export default PrivateRoute;
