import React, { useState } from "react";
import PropTypes from "prop-types";
import ReactMapboxGl from "react-mapbox-gl";

import RasterLayer from "./../../../../../components/RasterLayer";

import UserLocation from "./UserLocation";
import VineyardGeojson from "./VineyardGeojson";

const Map = ReactMapboxGl({
  accessToken:
    "pk.eyJ1IjoiY3JlaW1lcnMiLCJhIjoiY2p4cHZjZDdhMGs3ZzNvcDlxdXhzNzJwbyJ9.bz6je21hT4R6nL6LNT4dKQ",
});

const TheMap = ({ height, center, activeRow, ratingId }) => {
  const [zoom, setZoom] = useState(20);
  return (
    <Map
      style="mapbox://styles/mapbox/streets-v9" // eslint-disable-line react/style-prop-object
      containerStyle={{
        height: `${height}px`,
      }}
      center={center}
      zoom={[zoom]}
      onZoomEnd={(map, e) => {
        setZoom(map.transform._zoom);
        // HACK
        map.transform.maxZoom = 23;
      }}
    >
      <RasterLayer ratingId={ratingId} />
      <UserLocation />
      <VineyardGeojson activeRow={activeRow} ratingId={ratingId} />
    </Map>
  );
};

const propTypes = {
  height: PropTypes.number.isRequired,
  activeRow: PropTypes.object,
  center: PropTypes.arrayOf(PropTypes.number).isRequired,
  ratingId: PropTypes.number.isRequired,
};

TheMap.propTypes = propTypes;

export default TheMap;
