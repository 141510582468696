import React from "react";
import { Button, Icon } from "semantic-ui-react";
import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  top: 1rem;
  right: 1rem;
`;

const NorthSouth = styled.div`
  display: flex;
  flex-direction: column;
  > button:first-child {
    margin-bottom: 1rem;
  }
`;

const MoveButtons = ({ onMove }) => {
  return (
    <Wrapper>
      <Button icon primary onClick={() => onMove(270)}>
        <Icon name="arrow left" />
      </Button>
      <NorthSouth>
        <Button icon primary onClick={() => onMove(0)}>
          <Icon name="arrow up" />
        </Button>
        <Button icon primary onClick={() => onMove(180)}>
          <Icon name="arrow down" />
        </Button>
      </NorthSouth>
      <Button icon primary onClick={() => onMove(90)}>
        <Icon name="arrow right" />
      </Button>
    </Wrapper>
  );
};

MoveButtons.propTypes = {};

export default MoveButtons;
