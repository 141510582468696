import React from "react";
import { Loader } from "semantic-ui-react";
import useReactRouter from "use-react-router";
import gql from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";
import useOnlineStatus from "@rehooks/online-status";
import styled from "styled-components";

import HeaderWrapper from "./../../components/HeaderWrapper";
import BodyWrapper from "./../../components/BodyWrapper";
import RatingPad from "./../../components/RatingPad";

import { TRAITS_VIEW } from "./../../constants/routes";

import NewTraitTierModal from "./NewTraitTierModal";
import TraitTierList from "./TraitTierList";

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  overflow: hidden;
`;

const ListHalf = styled.div`
  flex: 2;
  display: flex;
  flex-direction: column;
`;
const PadHalf = styled.div`
  flex: 1;
  display: flex;
`;

const RatingPadWrapper = styled.div`
  padding: 0 3rem;
  flex: 1;
  display: flex;
  flex-direction: column;
`;

export const TRAIT = gql`
  query Trait($id: String!) {
    viewer {
      trait(id: $id) {
        id
        name
        traitTiers {
          id
          order
          name
          color
        }
      }
    }
  }
`;

const TraitsDetailView = () => {
  const { match } = useReactRouter();
  let { id } = match.params;
  id = parseInt(id);
  const online = useOnlineStatus();
  const { data, loading } = useQuery(TRAIT, {
    variables: { id },
    fetchPolicy: online ? "cache-and-network" : "cache-only",
  });
  if (loading) {
    return <Loader active />;
  }
  const { trait } = data.viewer;
  const traitTierNames = trait.traitTiers.map((t) => t.name);
  return (
    <React.Fragment>
      <HeaderWrapper
        header={trait.name}
        backlink={TRAITS_VIEW.pathname}
        action={
          <NewTraitTierModal traitId={id} traitTierNames={traitTierNames} />
        }
      />
      <BodyWrapper>
        <Wrapper>
          <ListHalf>
            <TraitTierList traitTiers={trait.traitTiers} traitId={id} />
          </ListHalf>
          <PadHalf>
            <RatingPadWrapper>
              <h3>Rating Input Preview</h3>
              <RatingPad
                traitTiers={trait.traitTiers}
                disabled={true}
                preview={true}
              />
            </RatingPadWrapper>
          </PadHalf>
        </Wrapper>
      </BodyWrapper>
    </React.Fragment>
  );
};

export default TraitsDetailView;
