import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { useMeasure } from "react-use";

import TheMap from "./TheMap";

const Wrapper = styled.div`
  height: 100%;
  position: relative;
`;

const VineyardMap = ({ ratingId, activeRow, activeVine }) => {
  const [ref, { height }] = useMeasure();
  return (
    <Wrapper ref={ref}>
      {height && (
        <TheMap
          height={height}
          center={activeVine.location.coordinates}
          ratingId={ratingId}
          activeRow={activeRow}
        />
      )}
    </Wrapper>
  );
};

const propTypes = {
  ratingId: PropTypes.number.isRequired,
  activeRow: PropTypes.object.isRequired,
  activeVine: PropTypes.object.isRequired,
};

VineyardMap.propTypes = propTypes;

export default VineyardMap;
