import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import gql from "graphql-tag";
import { useMutation } from "@apollo/react-hooks";
import { Button, Input, Modal } from "semantic-ui-react";
import { CirclePicker } from "react-color";

import {
  RED,
  ORANGE,
  YELLOW,
  OLIVE,
  GREEN,
  TEAL,
  GREY,
  BLACK
} from "../../../../constants/colors";

const UPDATE_TRAIT_TIER = gql`
  mutation updateTraitTier($input: CreateOrUpdateTraitTierMutationInput!) {
    createOrUpdateTraitTier(input: $input) {
      id
      name
      color
    }
  }
`;

const TRAIT = gql`
  query Trait($id: String!) {
    viewer {
      trait(id: $id) {
        id
        name
        traitTiers {
          id
          order
          name
          color
        }
      }
    }
  }
`;

const EditTraitTierModal = ({ traitTier, traitId, open, handleClose }) => {
  const [name, setName] = useState(null);
  const [color, setColor] = useState(null);
  const [loading, setLoading] = useState(false);

  const [createOrUpdateTraitTier] = useMutation(UPDATE_TRAIT_TIER, {
    update: (proxy, { data: { createOrUpdateTraitTier } }) => {
      const prev = proxy.readQuery({
        query: TRAIT,
        variables: { id: traitId }
      });

      const traitTierIndex = prev.viewer.trait.traitTiers.findIndex(
        t => t.id === traitTier.id
      );
      prev.viewer.trait.traitTiers[traitTierIndex].color =
        createOrUpdateTraitTier.color;
      prev.viewer.trait.traitTiers[traitTierIndex].name =
        createOrUpdateTraitTier.name;
      proxy.writeQuery({
        query: TRAIT,
        data: { ...prev },
        variables: { id: traitId }
      });
    }
  });

  useEffect(() => {
    if (traitTier) {
      setName(traitTier.name);
      setColor(traitTier.color);
    }
  }, [traitTier]);

  const saveChanges = async () => {
    setLoading(true);
    await createOrUpdateTraitTier({
      variables: { input: { name, trait: traitId, id: traitTier.id, color } }
    });
    setLoading(false);
    handleClose();
  };

  const handleColorSelected = (color, event) => {
    setColor(color.hex);
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Modal.Header>edit trait tier</Modal.Header>
      <Modal.Content>
        <p>color</p>
        {color && (
          <CirclePicker
            colors={[RED, ORANGE, YELLOW, OLIVE, GREEN, TEAL, GREY, BLACK]}
            color={color}
            onChangeComplete={handleColorSelected}
          />
        )}
        <p>name</p>
        <Input value={name || ""} onChange={(e, data) => setName(data.value)} />
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={handleClose}>cancel</Button>
        <Button
          color="green"
          onClick={saveChanges}
          loading={loading}
          disabled={loading}
        >
          save
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

EditTraitTierModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  traitTier: PropTypes.object,
  traitId: PropTypes.number.isRequired
};

export default EditTraitTierModal;
