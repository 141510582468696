import React from "react";
import PropTypes from "prop-types";
import { Button, Modal } from "semantic-ui-react";
import useOnlineStatus from "@rehooks/online-status";
import gql from "graphql-tag";
import { useMutation } from "@apollo/react-hooks";

import { useVineRatingMapping } from "../../../../context/vineRatingMapping";
import { RATING_QUERY_HASURA, useActiveRating } from "../../../../context/activeRating";
import {
  DELETE_VINE_ONLINE,
  REMOVE_OFFLINE_RATING,
} from "../../../../constants/queries";

const DELETE_VINE_OFFLINE = gql`
  mutation deleteVineOffline($vineNumber: Int!, $rowId: Int!) {
    deleteVineOffline(vineNumber: $vineNumber, rowId: $rowId) @client {
      id
      vineNumber
      rowId
    }
  }
`;

const DeleteConfirmModal = ({ ratingId, open, handleClose }) => {
  const { activeRow, setActiveRow, activeVine, setActiveVine } = useActiveRating();
  const online = useOnlineStatus();
  const { initializeVineRatingMapping } = useVineRatingMapping();
  const [removeOfflineRating] = useMutation(REMOVE_OFFLINE_RATING);

  /**
   *
   * @param {object} cache
   */
  const updateCacheAfterDelete = (cache) => {
    const prev = cache.readQuery({
      query: RATING_QUERY_HASURA,
      variables: { id: `${ratingId}` },
    });
    const vineyard = prev.rating_rating_by_pk.vineyard_vineyard;
    const vineRatings = prev.rating_rating_by_pk.rating_vineratings;

    // find and remove vine
    const activeRowIndex = vineyard.vineyard_rows.findIndex(
      (r) => r.id === activeRow.id
    );
    const vineIndex = vineyard.vineyard_rows[
      activeRowIndex
    ].vineyard_vines.findIndex((v) => v.number === activeVine.number);

    vineyard.vineyard_rows[activeRowIndex].vineyard_vines.splice(vineIndex, 1);
    vineyard.vineyard_rows[
      activeRowIndex
    ].vineyard_vines = vineyard.vineyard_rows[
      activeRowIndex
    ].vineyard_vines.map((v, index) => {
      if (index >= vineIndex) {
        return { ...v, number: (v.number -= 1) };
      } else {
        return v;
      }
    });

    // set new active vine
    const newActiveVine =
      vineyard.vineyard_rows[activeRowIndex].vineyard_vines.length - 1 >=
      vineIndex
        ? vineyard.vineyard_rows[activeRowIndex].vineyard_vines[vineIndex]
        : vineyard.vineyard_rows[activeRowIndex].vineyard_vines[vineIndex - 1];

    // find and remove vine rating
    const vineRatingIndex = vineRatings.findIndex(
      (v) => v.vineyard_vine.id === activeVine.id
    );
    if (vineRatingIndex >= 0) {
      vineRatings.splice(vineRatingIndex, 1);
    }

    const vineRatingsInRow = vineRatings
      .filter((v) => v.vineyard_vine)
      .filter(
        (vineRating) =>
          vineRating.vineyard_vine.vineyard_row.id === activeRow.id
      );

    // update the vine ratings...
    for (const vineRating of vineRatingsInRow) {
      const vineyard_vine_id = vineRating.vineyard_vine.id;
      const updatedVine = vineyard.vineyard_rows[
        activeRowIndex
      ].vineyard_vines.find((vine) => vine.id === vineyard_vine_id);
      vineRating.vineyard_vine = {
        ...updatedVine,
        vineyard_row: { id: activeRow.id, __typename: "vineyard_row" },
      };
    }

    // find and remove vine ratings from offline cache
    const variables = {
      vineNumber: activeVine.number,
      rowId: activeRow.id,
    };
    removeOfflineRating({ variables });

    // write data to cache
    const data = { ...prev };
    data.rating_rating_by_pk.vineyard_vineyard = vineyard;
    data.rating_rating_by_pk.ratings_vineratings = vineRatings;

    cache.writeQuery({
      query: RATING_QUERY_HASURA,
      variables: { id: `${ratingId}` },
      data,
    });
    setActiveRow(vineyard.vineyard_rows[activeRowIndex]);
    setActiveVine(newActiveVine);
    initializeVineRatingMapping(vineRatings);
  };
  const [deleteVineOnline, { loading }] = useMutation(DELETE_VINE_ONLINE, {
    onCompleted: () => handleClose(),
    update: (proxy, mutationResult) => {
      updateCacheAfterDelete(proxy);
    },
  });

  const [deleteVineOffline] = useMutation(DELETE_VINE_OFFLINE, {
    onCompleted: () => handleClose(),
    update: (proxy, mutationResult) => {
      updateCacheAfterDelete(proxy);
    },
  });

  const handleDeleteVine = () => {
    const input = {
      vineNumber: activeVine.number,
      rowId: activeRow.id,
    };
    if (online) {
      deleteVineOnline({ variables: { input: { vines: [input] } } });
    } else {
      deleteVineOffline({ variables: { ...input } });
    }
  };

  const content = `Are you sure you want to delete vine ${activeVine.number} of row ${activeRow.number}?`;

  return (
    <Modal open={open}>
      <Modal.Header>Delete?</Modal.Header>
      <Modal.Content>
        <p>{content}</p>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={handleClose}>cancel</Button>
        <Button onClick={handleDeleteVine} loading={loading} primary>
          Yes, delete
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

DeleteConfirmModal.propTypes = {
  activeRow: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default DeleteConfirmModal;
