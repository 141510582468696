import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import RowPicker from "./RowPicker";
import { Checkbox, Icon } from "semantic-ui-react";
import { INACTIVE_COLOR } from "./../../../../constants/colors";
import { useActiveRating } from "./../../../../context/activeRating";
import { useVineRatingMapping } from "./../../../../context/vineRatingMapping";

const Wrapper = styled.div`
  height: 75px;
  display: flex;
`;

const PickerWrapper = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
`;

const VinesWrapper = styled.div`
  flex: 3;
  padding-top: 1rem;
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
`;

const VineWrapper = styled.div`
  min-width: 40px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  background: ${(props) => (props.active ? "black" : "white")};
  color: ${(props) => (props.active ? "white" : "black")};
`;

const VineNumber = styled.div`
  flex: 1;
`;

const Vine = styled.div`
  flex: 1;
  width: 25%;
  background: ${(props) =>
    props.rating !== undefined &&
    props.rating.trait_traittier &&
    props.rating.trait_traittier.id // otherwise its just a comment!
      ? props.color
      : INACTIVE_COLOR};
`;

const RowPickerWrapper = styled.div`
  display: flex;
  align-items: center;
  flex: 2;
  height: 100%;
`;

const ReverseWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex: 1;
`;

const RowBar = ({
  activeVineNumber,
  rows,
  handleSetActiveVine,
  reverse,
  handleReverse,
}) => {
  const { vineRatingMapping } = useVineRatingMapping();

  const { activeRow, activeTrait } = useActiveRating();
  const themVines =
    reverse === true
      ? [...activeRow.vineyard_vines].reverse()
      : activeRow.vineyard_vines;

  if (!activeRow) {
    return null;
  }
  return (
    <Wrapper>
      <PickerWrapper>
        <RowPickerWrapper>
          <RowPicker rows={rows} />
        </RowPickerWrapper>
        <ReverseWrapper>
          <Icon name="repeat" size="large" />
          <Checkbox
            toggle
            checked={reverse}
            onChange={(_event, data) => handleReverse(data.checked)}
          />
        </ReverseWrapper>
      </PickerWrapper>
      <VinesWrapper>
        {themVines.map((vine, index) => {
          const key = `${activeTrait.trait_trait.id}-${activeRow.id}-${vine.number}`;
          const vineRating = vineRatingMapping[key];
          let color = INACTIVE_COLOR;
          if (vineRating && vineRating.trait_traittier) {
            color = vineRating.trait_traittier.color;
          }

          return (
            <VineWrapper
              key={`${index}`}
              onClick={() => handleSetActiveVine(vine)}
              active={vine.number === activeVineNumber}
            >
              <VineNumber>{vine.number}</VineNumber>
              <Vine color={color} rating={vineRating} />
            </VineWrapper>
          );
        })}
      </VinesWrapper>
    </Wrapper>
  );
};

const propTypes = {
  activeVineNumber: PropTypes.number,
  rows: PropTypes.array.isRequired,
  handleSetActiveVine: PropTypes.func.isRequired,
  reverse: PropTypes.bool.isRequired,
  handleReverse: PropTypes.func.isRequired,
};

RowBar.propTypes = propTypes;

export default RowBar;
