import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useQuery } from "@apollo/react-hooks";
import { GeoJSONLayer } from "react-mapbox-gl";
import {
  point,
  multiPoint,
  multiLineString,
  featureCollection,
} from "@turf/helpers";
import bbox from "@turf/bbox";
import buffer from "@turf/buffer";
import bboxPolygon from "@turf/bbox-polygon";

import { useActiveRating } from "../../../../../../context/activeRating";
import { ACTIVE_COLOR } from "../../../../../../constants/colors";
import { RATING_QUERY_HASURA } from "../../../../../../context/activeRating";

const VINE_RADIUS = 5;

const BackgroundGeojon = ({ ratingId, action, activeRow }) => {
  const [vineyardFeatures, setVineyardFeatures] = useState(null);
  const { data } = useQuery(RATING_QUERY_HASURA, {
    variables: { id: `${ratingId}` },
    fetchPolicy: "cache-only",
  });
  const { activeVine } = useActiveRating();

  useEffect(() => {
    if (data) {
      const rows = data.rating_rating_by_pk.vineyard_vineyard.vineyard_rows;

      let vineCoords = [];

      const otherRowsCoords = [];

      for (const row of rows) {
        let vines = row.vineyard_vines;
        if (action === "edit" && row.id === activeRow.id) {
          vines = vines.filter((v) => v.number !== activeVine.number);
        }
        const rowVineCoords = vines.map((v) => v.location.coordinates);

        vineCoords = [...vineCoords, ...rowVineCoords];

        const firstVine = row.vineyard_vines[0];
        const lastVine = row.vineyard_vines[row.vineyard_vines.length - 1];
        const start = firstVine.location.coordinates;
        const end = lastVine.location.coordinates;
        const line = [start, end];
        otherRowsCoords.push(line);
      }

      const vineCoordsGeojson = multiPoint(vineCoords, {
        circleColor: "lightblue",
        circleRadius: VINE_RADIUS,
      });

      const otherRowsGeojson = multiLineString(otherRowsCoords, {
        lineColor: "lightblue",
        circleColor: "transparent",
      });

      // POLES
      const poles = rows.map((r) => r.vineyard_poles);
      const flatPoles = [].concat(...poles.map((p) => p));
      const poleCoords = flatPoles.map((p) => p.location.coordinates);

      const RECT_SIZE = 0.0002;
      const UNITS = "kilometers";

      const poleFeatures = poleCoords.map((p) => {
        const polePoint = point(p);
        const buffered = buffer(polePoint, RECT_SIZE, { units: UNITS });
        const bufferedBbox = bbox(buffered);
        const bufferedBboxPolygon = bboxPolygon(bufferedBbox, {
          properties: {
            color: "#fbbd08",
            circleColor: "transparent",
            lineColor: "transparent",
          },
        });
        return bufferedBboxPolygon;
      });

      const features = [...poleFeatures, vineCoordsGeojson, otherRowsGeojson];

      if (action === "add") {
        const activeVinePoint = point(activeVine.location.coordinates, {
          circleColor: ACTIVE_COLOR,
          circleRadius: 8,
          lineColor: "transparent",
        });
        features.push(activeVinePoint);
      }

      // assemble all features
      const allFeatures = featureCollection(features);
      setVineyardFeatures(allFeatures);
    }
  }, [data, activeRow, activeVine]); // eslint-disable-line react-hooks/exhaustive-deps

  if (vineyardFeatures) {
    return (
      <GeoJSONLayer
        data={vineyardFeatures}
        fillPaint={{
          "fill-color": ["get", "color"],
        }}
        circlePaint={{
          "circle-color": ["get", "circleColor"],
          "circle-radius": ["get", "circleRadius"],
        }}
        linePaint={{ "line-color": ["get", "lineColor"], "line-width": 2 }}
      />
    );
  }
  return null;
};

BackgroundGeojon.propTypes = {
  ratingId: PropTypes.number.isRequired,
  activeRow: PropTypes.object.isRequired,
};

export default BackgroundGeojon;
