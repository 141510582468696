import React from "react";
import PropTypes from "prop-types";
import { Header } from "semantic-ui-react";
import styled from "styled-components";

import BackButton from "./BackButton";
import OfflineRatings from "./OfflineRatings";

const Wrapper = styled.div`
  margin: 0.5rem 1rem;
  display: flex;
  justify-content: space-between;
  height: 45px;
`;

const HeaderAndBack = styled.div`
  display: flex;
  align-items: center;
`;

const StyledHeader = styled(Header)`
  margin-top: 0;
  margin-bottom: 0;
  margin-right: 1rem;
`;

const propTypes = {
  children: PropTypes.node,
  backlink: PropTypes.string,
  header: PropTypes.string.isRequired
};

const HeaderWrapper = ({ backlink, header, action }) => {
  return (
    <Wrapper>
      <HeaderAndBack>
        {backlink && <BackButton to={backlink} />}
        <StyledHeader as="h2">{header}</StyledHeader>
        {action && action}
      </HeaderAndBack>
      <OfflineRatings />
    </Wrapper>
  );
};

HeaderWrapper.propTypes = propTypes;

export default HeaderWrapper;
