import * as React from "react";

import { ActiveRatingProvider } from "./../../context/activeRating";
import { UserProvider } from "./../../context/user";
import { VineRatingMappingProvider } from "./../../context/vineRatingMapping";

const AppProviers = ({ children }) => {
  return (
    <UserProvider>
      <VineRatingMappingProvider>
        <ActiveRatingProvider>{children}</ActiveRatingProvider>
      </VineRatingMappingProvider>
    </UserProvider>
  );
};

export default AppProviers;
