import React, { useState } from "react";
import gql from "graphql-tag";
import { Confirm, Dropdown } from "semantic-ui-react";
import styled from "styled-components";
import PropTypes from "prop-types";
import useOnlineStatus from "@rehooks/online-status";
import { useMutation, useQuery } from "@apollo/react-hooks";

import { useVineRatingMapping } from "../../context/vineRatingMapping";
import { useActiveRating } from "./../../context/activeRating";
import { useUser } from "../../context/user";

import {
  BULK_RATE_VINE_HASURA,
  UPDATE_VINE_RATING_HASURA,
} from "../../constants/queries";
import { OFFLINE_RATINGS } from "../HeaderWrapper/OfflineRatings";
import RatingPad from "../RatingPad";

import CameraModal from "./CameraModal";
import CommentModal from "./CommentModal";
// import RatingHeader from "./RatingHeader";
import VineCRUDModal from "./VineCRUDModal";

const Wrapper = styled.div`
  position: relative;
  height: 100%;
  margin-right: 1rem;
  overflow: hidden;
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const VineActionsWrapper = styled.div`
  margin-top: 0.5rem;
  margin-right: 1rem;
  display: flex;
  justify-content: space-between;
`;

const RATE_VINE_OFFLINE = gql`
  mutation addOrUpdateOfflineRating(
    $id: Int
    $createdBy: Int!
    $rating: Int!
    $traitTierId: Int!
    $traitTierColor: String!
    $traitTierName: String!
    $vine: Int
    $vineId: Int
    $polygonId: Int
    $polygonNumber: Int
    $row: Int
    $comment: String
  ) {
    addOrUpdateOfflineRating(
      id: $id
      createdBy: $createdBy
      rating: $rating
      traitTierId: $traitTierId
      traitTierColor: $traitTierColor
      traitTierName: $traitTierName
      vine: $vine
      vineId: $vineId
      polygonId: $polygonId
      polygonNumber: $polygonNumber
      row: $row
      comment: $comment
    ) @client {
      id
      comment
      created
      trait_traittier {
        id
        color
        name
      }
      vineyard_vine {
        id
      }
      vineyard_polygon {
        id
        number
      }
    }
  }
`;

const RATING_VINE_RATINGS_QUERY_HASURA = gql`
  query ratingVineRatings($id: Int!) {
    rating_rating_by_pk(id: $id) {
      id
      rating_vineratings(
        order_by: { vineyard_vine: { row_id: asc, number: asc } }
      ) {
        comment
        created
        id
        vineyard_vine {
          number
          id
          vineyard_row {
            id
          }
        }
        vineyard_polygon {
          id
          number
        }
        trait_traittier {
          trait_trait {
            id
          }
          color
          id
          name
        }
      }
    }
  }
`;

const RatingForm = ({ handleAfterVineRated }) => {
  const [loading, setLoading] = useState(null);
  const [showConfirm, setShowConfirm] = useState(false);
  const online = useOnlineStatus();
  const { data } = useQuery(OFFLINE_RATINGS);
  const { vineRatingMapping, polygonRatingMapping } = useVineRatingMapping();
  const { user } = useUser();

  const {
    activeRating,
    activeTrait,
    setActiveTrait,
    activePolygon,
    activeRow,
    activeVine,
  } = useActiveRating();
  const ratingId = activeRating.rating_rating_by_pk.id;
  const traitTiers = activeTrait.trait_trait.trait_traittiers;

  const existingOfflineRatings =
    data && data.offlineRatings !== undefined
      ? data.offlineRatings.length > 0
      : false;

  let existingRating = null;
  if (activeVine !== null) {
    existingRating =
      vineRatingMapping[
        `${activeTrait.trait_trait.id}-${activeRow.id}-${activeVine.number}`
      ] !== undefined
        ? vineRatingMapping[
            `${activeTrait.trait_trait.id}-${activeRow.id}-${activeVine.number}`
          ]
        : null;
  } else if (activePolygon !== null) {
    existingRating =
      polygonRatingMapping[
        `${activeTrait.trait_trait.id}-${activePolygon.id}`
      ] !== undefined
        ? polygonRatingMapping[
            `${activeTrait.trait_trait.id}-${activePolygon.id}`
          ]
        : null;
  }
  const existingComment = existingRating ? existingRating.comment : null;

  /**
   * updates the apollo cache after the mutation
   *
   * @param {object} cache - apollo cache
   * @param {object} result - mutation result, online or offline
   * @param {boolean} update - whether or not an existing datapoint was updated
   */
  const updateCacheAfterRating = (
    cache,
    result = {
      id: 1,
      comment: "comment",
      created: new Date(),
      trait_tier_id: 1,
      trait_tier_color: "#f00000",
      trait_tier_name: "jonny",
      vine_id: 12 || null,
      polygon_id: 11 || null,
      polygon_number: 11 || null,
      vine_number: 30 || null,
    },
    update = false
  ) => {
    // update rating
    const prev = cache.readQuery({
      query: RATING_VINE_RATINGS_QUERY_HASURA,
      variables: { id: `${ratingId}` },
    });

    let justAComment = false;

    let vineRatings = [];
    let vineRating = null;

    if (!update) {
      ///////////////////////////
      // assemble new vine rating
      ///////////////////////////

      const newVineRating = {
        __typename: "rating_vinerating",
        id: result.id || parseInt(Math.random() * 100),
        comment: result.comment,
        created: result.created,
      };

      if (result.vine_id) {
        newVineRating.vineyard_vine = {
          __typename: "vineyard_vine",
          number: result.vine_number,
          id: result.vine_id,
          vineyard_row: {
            __typename: "vineyard_row",
            id: activeRow.id,
          },
        };
        newVineRating.vineyard_polygon = null;
      } else {
        newVineRating.vineyard_polygon = {
          __typename: "vineyard_polygon",
          id: result.polygon_id,
          number: result.polygon_number,
        };
        newVineRating.vineyard_vine = null;
      }
      // here the real trait tier should be referenced
      if (result.trait_tier_id) {
        newVineRating.trait_traittier = {
          __typename: "trait_traittier",
          id: result.trait_tier_id,
          color: result.trait_tier_color,
          name: result.trait_tier_name,
        };
      } else {
        newVineRating.trait_traittier = null;
      }

      vineRating = newVineRating;
      vineRatings = [
        ...prev.rating_rating_by_pk.rating_vineratings,
        newVineRating,
      ];

      if (newVineRating.trait_traittier === null) {
        justAComment = true;
      }
      const data = { ...prev };
      data.rating_rating_by_pk.rating_vineratings = vineRatings;

      cache.writeQuery({
        query: RATING_VINE_RATINGS_QUERY_HASURA,
        variables: { id: `${ratingId}` },
        data,
      });
      console.log("wrote to cache");
    } else {
      if (result.vine_id) {
        ////////////////////////////
        // find existing vine rating
        ////////////////////////////
        const existingRatingStoreIndex =
          prev.rating_rating_by_pk.rating_vineratings
            .filter((r) => r.vineyard_vine !== null)
            .findIndex(
              (v) =>
                v.vineyard_vine.number === result.vine_number &&
                v.vineyard_vine.vineyard_row.id === activeRow.id &&
                v.trait_traittier.trait_trait.id === activeTrait.trait_trait.id
            );
        const existingVineRating = {
          ...prev.rating_rating_by_pk.rating_vineratings[
            existingRatingStoreIndex
          ],
        };
        vineRating = existingVineRating;
      } else {
        ///////////////////////////////
        // find existing polygon rating
        ///////////////////////////////
        const existingRatingStoreIndex =
          prev.rating_rating_by_pk.rating_vineratings
            .map((v) => {
              return v;
            })
            .filter((r) => r.vineyard_polygon !== null)
            .findIndex((v) => {
              return v.vineyard_polygon.id === result.polygon_id;
            });
        const existingVineRating = {
          ...prev.rating_rating_by_pk.rating_vineratings[
            existingRatingStoreIndex
          ],
        };
        vineRating = existingVineRating;
      }
    }

    // finally done with all of this shit...
    setLoading(null);
    const incrementActiveVine = justAComment === false;
    handleAfterVineRated(result.vine_number, vineRating, incrementActiveVine);
  };

  const [addOrUpdateOfflineRating] = useMutation(RATE_VINE_OFFLINE, {
    update: (proxy, { data: { addOrUpdateOfflineRating } }) => {
      const result = {
        comment: addOrUpdateOfflineRating.comment,
        id: addOrUpdateOfflineRating.id,
        created: addOrUpdateOfflineRating.created,
        trait_tier_id: addOrUpdateOfflineRating.trait_traittier.id,
        trait_tier_color: addOrUpdateOfflineRating.trait_traittier.color,
        trait_tier_name: addOrUpdateOfflineRating.trait_traittier.name,
        vine_id: addOrUpdateOfflineRating?.vineyard_vine?.id,
        vine_number: addOrUpdateOfflineRating?.vineyard_vine?.number,
        polygon_id: addOrUpdateOfflineRating?.vineyard_polygon?.id,
        polygon_number: addOrUpdateOfflineRating?.vineyard_polygon?.number,
      };
      const update = typeof addOrUpdateOfflineRating.id === "number";
      updateCacheAfterRating(proxy, result, update);
    },
  });

  const [createVineRating] = useMutation(BULK_RATE_VINE_HASURA, {
    update: (cache, { data }) => {
      const vineRating = data.insert_rating_vinerating.returning[0];
      const result = {
        id: vineRating.id,
        comment: vineRating.comment,
        created: vineRating.created,
        vine_id: vineRating?.vineyard_vine?.id || null,
        vine_number: vineRating?.vineyard_vine?.number || null,
        polygon_id: vineRating?.vineyard_polygon?.id || null,
        polygon_number: vineRating?.vineyard_polygon?.number || null,
      };
      if (vineRating.trait_traittier) {
        result.trait_tier_id = vineRating.trait_traittier.id;
        result.trait_tier_color = vineRating.trait_traittier.color;
        result.trait_tier_name = vineRating.trait_traittier.name;
      }
      updateCacheAfterRating(cache, result, false);
    },
  });
  const [updateVineRating] = useMutation(UPDATE_VINE_RATING_HASURA, {
    update: (cache, { data }) => {
      const vineRating = data.update_rating_vinerating_by_pk;
      const result = {
        id: vineRating.id,
        comment: vineRating.comment,
        trait_tier_id: vineRating.trait_traittier.id,
        trait_tier_color: vineRating.trait_traittier.color,
        trait_tier_name: vineRating.trait_traittier.name,
        vine_id: vineRating?.vineyard_vine?.id || null,
        vine_number: vineRating?.vineyard_vine?.number || null,
        polygon_id: vineRating?.vineyard_polygon?.id || null,
        polygon_number: vineRating?.vineyard_polygon?.number || null,
      };
      updateCacheAfterRating(cache, result, true);
    },
  });

  const commentOnVineRating = (comment) => {
    if (online) {
      if (existingRating) {
        const input = {
          comment,
        };
        const pk_columns = { id: existingRating.id };
        updateVineRating({
          variables: { pk_columns, input },
        });
      } else {
        const input = {
          comment: comment,
          created: new Date().toISOString(),
          created_by_id: user.id,
          rating_id: ratingId,
          vine_id: activeVine?.id || null,
          polygon_id: activePolygon?.id || null,
        };
        createVineRating({ variables: { input: [input] } });
      }
    }
  };

  /**
   * assemble the input and call the adequate mutation
   * based on the online status
   *
   * @param {object} traitTier
   * @param {string} comment
   */
  const submitRating = (traitTier, comment) => {
    console.log("####################################################");
    setLoading(traitTier.id);
    if (online) {
      if (existingRating) {
        const input = {
          comment,
          trait_tier_id: traitTier.id,
        };
        const pk_columns = { id: existingRating.id };
        updateVineRating({
          variables: { pk_columns, input },
        });
      } else {
        const input = {
          comment: comment || "",
          created: new Date().toISOString(),
          created_by_id: user.id,
          rating_id: ratingId,
          trait_tier_id: traitTier.id,
          vine_id: activeVine?.id || null,
          polygon_id: activePolygon?.id || null,
        };
        createVineRating({ variables: { input: [input] } });
      }
    } else {
      const variables = {
        createdBy: user.id,
        rating: ratingId,
        traitTierId: traitTier.id,
        traitTierColor: traitTier.color,
        traitTierName: traitTier.name,
        vine: activeVine?.number || null,
        vineId: activeVine?.id || null,
        polygonId: activePolygon?.id || null,
        polygonNumber: activePolygon?.number || null,
        row: activeRow?.id || null,
        comment: comment || "",
      };
      // in case of update
      if (existingRating) {
        variables.id = existingRating.id;
      } else {
        variables.id = null;
      }
      addOrUpdateOfflineRating({ variables });
    }
  };

  return (
    <React.Fragment>
      <Confirm
        open={showConfirm}
        onCancel={() => setShowConfirm(false)}
        onConfirm={() => setShowConfirm(false)}
        content="Please sync offline ratings before you can continue."
      />
      <Wrapper id="wrapper">
        {/* <RatingHeader
          traitName={traitName}
          vineyardName={vineyardName}
          ratingName={ratingName}
        /> */}
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginBottom: "1rem",
          }}
        >
          <div style={{ marginRight: "1rem" }}>
            <span style={{ fontWeight: "bold" }}>Field:</span>{" "}
            {activeRating.rating_rating_by_pk.vineyard_vineyard.name}
          </div>
          <div style={{ display: "flex", alignItems: "center", flex: 1 }}>
            <div style={{ marginRight: "1rem", fontWeight: "bold" }}>
              Trait:
            </div>
            <div style={{ flex: 1 }}>
              <Dropdown
                fluid
                selection
                options={activeRating.rating_rating_by_pk.rating_rating_traits.map(
                  (t) => {
                    const trait = t.trait_trait;
                    return { key: trait.id, text: trait.name, value: trait.id };
                  }
                )}
                value={activeTrait.trait_trait.id}
                onChange={(_e, data) => {
                  const id = data.value;
                  const trait =
                    activeRating.rating_rating_by_pk.rating_rating_traits.find(
                      (r) => r.trait_trait.id === id
                    );
                  setActiveTrait(trait);
                }}
              />
            </div>
          </div>
        </div>
        {!traitTiers.length && "For this trait there are no classes (yet)."}
        <RatingPad
          traitTiers={traitTiers}
          handleClick={(traitTier) => {
            if (existingOfflineRatings && online) {
              setShowConfirm(true);
              return;
            }
            submitRating(traitTier, existingComment);
          }}
          disabled={loading !== null}
          loading={loading}
        />
      </Wrapper>
      <VineActionsWrapper>
        <CommentModal
          vine={activeVine}
          polygon={activePolygon}
          handleSubmit={commentOnVineRating}
        />
        {activeVine && (
          <CameraModal
            vine={activeVine}
            activeRow={activeRow}
            ratingId={ratingId}
          />
        )}
        {activeVine && (
          <VineCRUDModal
            activeVine={activeVine}
            activeRow={activeRow}
            ratingId={ratingId}
          />
        )}
      </VineActionsWrapper>
    </React.Fragment>
  );
};

const propTypes = {
  handleAfterVineRated: PropTypes.func.isRequired,
};

RatingForm.propTypes = propTypes;

export default RatingForm;
