import React, { useState } from "react";

import styled from "styled-components";
import { Button, Form, Message, Segment } from "semantic-ui-react";
import { Formik, Form as FormikForm } from "formik";
import useReactRouter from "use-react-router";
import { useApolloClient, useQuery } from "@apollo/react-hooks";

import gql from "graphql-tag";

import {
  JWT_TOKEN_KEY,
  REFRESH_TOKEN_KEY,
  REST_API_URI,
} from "./../../constants";
import { RATINGS_VIEW } from "./../../constants/routes";

import image from "./logo.png";

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const FormWrapper = styled.div`
  width: 100%;
  max-width: 450px;
`;

const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 150px;
  margin: 3rem auto 0 auto;

  > img {
    max-width: 100%;
  }
`;

const AUTH_CHECK = gql`
  {
    isAuthenticated @client
  }
`;

const LoginView = () => {
  const { history } = useReactRouter();
  const client = useApolloClient();
  const [error, setError] = useState(false);
  const tokenAuth = async (email, password) => {
    try {
      const url = `${REST_API_URI}/authenticate/`;
      const response = await fetch(url, {
        method: "POST",
        body: JSON.stringify({ email, password }),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });
      if (response.ok) {
        const responseData = await response.json();
        localStorage.setItem(JWT_TOKEN_KEY, responseData.token);
        localStorage.setItem(REFRESH_TOKEN_KEY, responseData.refreshToken);
        client.writeData({
          data: { isAuthenticated: true },
        });
        history.push({ pathname: RATINGS_VIEW.pathname });
      } else {
        setError(true);
      }
    } catch (error) {
      console.log(error);
      setError(true);
    }
  };
  const { data } = useQuery(AUTH_CHECK);
  return (
    <Wrapper>
      <FormWrapper>
        <ImageWrapper>
          <img src={image} alt="man sniffing wine" />
        </ImageWrapper>
        <Segment stacked>
          {error ? <Message color="red">Wrong credentials.</Message> : null}
          {data.isAuthenticated ? (
            <Message color="yellow">
              Sorry you ended up here, you are logged in. Please use the main
              menu to navigate away.
            </Message>
          ) : null}
          <Formik
            initialValues={{ email: "", password: "" }}
            onSubmit={(values, { setSubmitting }) => {
              tokenAuth(values.email, values.password);
            }}
          >
            {({ values, handleChange, handleSubmit, isSubmitting }) => (
              <FormikForm onSubmit={handleSubmit} className="ui large form">
                <Form.Input
                  type="email"
                  placeholder="E-Mail"
                  name="email"
                  value={values.email}
                  onChange={handleChange}
                  fluid
                />
                <Form.Input
                  type="password"
                  placeholder="Passwort"
                  name="password"
                  value={values.password}
                  onChange={handleChange}
                  fluid
                />
                <Button
                  disabled={
                    (isSubmitting && !error) ||
                    !values.email ||
                    !values.password
                  }
                  loading={isSubmitting && !error}
                  primary
                  fluid
                >
                  Login
                </Button>
              </FormikForm>
            )}
          </Formik>
        </Segment>
      </FormWrapper>
    </Wrapper>
  );
};

export default LoginView;
