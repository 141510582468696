import React, { useState } from "react";
import gql from "graphql-tag";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { Button, Form, Loader, Modal, Dropdown } from "semantic-ui-react";
import { Formik, Form as FormikForm } from "formik";
import styled from "styled-components";
import useOnlineStatus from "@rehooks/online-status";

const InputWrapper = styled.div`
  margin-bottom: 1rem;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 1rem;
`;

const CREATE_RATING = gql`
  mutation createRating($input: CreateOrUpdateRatingMutationInput!) {
    createOrUpdateRating(input: $input) {
      id
      created
      trait
      vineyard
      name
    }
  }
`;

const INSERT_RATING = gql`
  mutation insert_rating_rating($objects: [rating_rating_insert_input!]!) {
    insert_rating_rating(objects: $objects) {
      returning {
        id
      }
    }
  }
`;

const GET_TRAITS_AND_VINEYARDS = gql`
  {
    viewer {
      user {
        id
        email
      }
      projects {
        id
        name
        traits {
          id
          name
        }
        vineyards {
          id
          name
          flikNumber
        }
      }
    }
  }
`;

const NewRatingModal = () => {
  const [showModal, setShowModal] = useState(false);
  const online = useOnlineStatus();

  const { data, loading } = useQuery(GET_TRAITS_AND_VINEYARDS, {
    fetchPolicy: "cache-and-network",
  });

  const [createRating] = useMutation(CREATE_RATING, {
    onCompleted: (data) => {
      setShowModal(false);
    },
    refetchQueries: () => {
      return ["getRatings"];
    },
  });

  const [insertRating] = useMutation(INSERT_RATING, {
    onCompleted: (data) => {
      setShowModal(false);
    },
    refetchQueries: () => {
      return ["getRatings"];
    },
  });

  if (loading) {
    return <Loader active />;
  }

  if (data && data.viewer) {
    const projectOptions = data.viewer.projects.map((project) => ({
      key: project.id,
      value: project.id,
      text: project.name,
    }));

    return (
      <Modal
        trigger={
          <Button
            color="green"
            onClick={() => setShowModal(true)}
            disabled={!online}
          >
            add rating
          </Button>
        }
        open={showModal}
        onClose={() => setShowModal(false)}
      >
        <Modal.Header>add new rating</Modal.Header>
        <Modal.Content>
          <Formik
            initialValues={{ project: "", traits: [], vineyard: "", name: "" }}
            onSubmit={(values) => {
              const objects = {
                name: values.name,
                created_by_id: data.viewer.user.id,
                project_id: values.project,
                created: new Date().toISOString(),
                vineyard_id: values.vineyard,
                rating_rating_traits: {
                  data: values.traits.map((t) => ({ trait_id: Number(t) })),
                },
              };
              insertRating({ variables: { objects: [objects] } });
              // createRating({
              //   variables: {
              //     input: {
              //       createdBy: data.viewer.user.id,
              //       trait: values.traits,
              //       vineyard: values.vineyard,
              //       project: values.project,
              //       name: values.name,
              //     },
              //   },
              // });
            }}
          >
            {({
              values,
              handleChange,
              handleSubmit,
              isSubmitting,
              setFieldValue,
            }) => {
              const activeProject = data.viewer.projects.find(
                (p) => p.id === values.project
              );
              let traitOptions = [];
              let vineyardOptions = [];
              if (activeProject) {
                traitOptions = activeProject.traits.map((trait) => ({
                  key: trait.id,
                  value: trait.id,
                  text: trait.name,
                }));
                vineyardOptions = activeProject.vineyards.map((vineyard) => ({
                  key: vineyard.id,
                  value: vineyard.id,
                  text: vineyard.name,
                }));
              }

              return (
                <FormikForm onSubmit={handleSubmit}>
                  <InputWrapper>
                    <label>Name</label>
                    <Form.Input
                      type="text"
                      placeholder="Name"
                      name="name"
                      value={values.name}
                      onChange={handleChange}
                      fluid
                    />
                  </InputWrapper>
                  <InputWrapper>
                    <label>Project</label>
                    <Dropdown
                      selection
                      placeholder="Select project..."
                      fluid
                      value={values.project}
                      options={projectOptions}
                      onChange={(evt, data) => {
                        setFieldValue("trait", "");
                        setFieldValue("vineyard", "");
                        setFieldValue("project", data.value);
                      }}
                    />
                  </InputWrapper>
                  <InputWrapper>
                    <label>Traits</label>
                    <Dropdown
                      selection
                      placeholder="Select traits..."
                      multiple
                      fluid
                      value={values.traits}
                      options={traitOptions}
                      onChange={(evt, data) =>
                        setFieldValue("traits", data.value)
                      }
                    />
                  </InputWrapper>

                  <InputWrapper>
                    <label>Field</label>
                    <Dropdown
                      selection
                      placeholder="Select fields..."
                      fluid
                      value={values.vineyard}
                      options={vineyardOptions}
                      onChange={(evt, data) =>
                        setFieldValue("vineyard", data.value)
                      }
                    />
                  </InputWrapper>
                  <ButtonWrapper>
                    <Button onClick={() => setShowModal(false)}>cancel</Button>
                    <Button
                      disabled={
                        isSubmitting || !values.traits || !values.vineyard
                      }
                      loading={isSubmitting}
                      primary
                    >
                      save
                    </Button>
                  </ButtonWrapper>
                </FormikForm>
              );
            }}
          </Formik>
        </Modal.Content>
      </Modal>
    );
  }
  return null;
};

export default NewRatingModal;
