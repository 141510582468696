import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Button, Modal } from "semantic-ui-react";
import styled from "styled-components";
import { point } from "@turf/helpers";
import transformTranslate from "@turf/transform-translate";
import useOnlineStatus from "@rehooks/online-status";
import gql from "graphql-tag";
import { useMutation } from "@apollo/react-hooks";

import {
  CREATE_VINE_ONLINE,
  EDIT_VINE_ONLINE_HASURA,
} from "../../../../constants/queries";
import { useVineRatingMapping } from "../../../../context/vineRatingMapping";
import {
  RATING_QUERY_HASURA,
  useActiveRating,
} from "../../../../context/activeRating";

import BeforeAfterSelector from "./BeforeAfterSelector";
import Map from "./Map";
import MoveButtons from "./MoveButtons";

const CREATE_VINE_OFFLINE = gql`
  mutation createVineOffline(
    $newVineNumber: Int!
    $rowId: Int!
    $longitude: Float!
    $latitude: Float!
  ) {
    createVineOffline(
      newVineNumber: $newVineNumber
      rowId: $rowId
      longitude: $longitude
      latitude: $latitude
    ) @client {
      id
      newVineNumber
      rowId
      location {
        longitude
        latitude
      }
    }
  }
`;

const EDIT_VINE_OFFLINE = gql`
  mutation editVineOffline(
    $vineId: Int!
    $vineNumber: Int!
    $rowId: Int!
    $longitude: Float!
    $latitude: Float!
  ) {
    editVineOffline(
      vineNumber: $vineNumber
      vineId: $vineId
      rowId: $rowId
      longitude: $longitude
      latitude: $latitude
    ) @client {
      id
      number
      vineyard_row {
        id
      }
      location
    }
  }
`;

const Wrapper = styled.div`
  position: relative;
`;

const updateCacheAfterCreating = (
  cache,
  ratingId,
  setActiveVine,
  activeRow,
  setActiveRow,
  initializeVineRatingMapping,
  result
) => {
  const prev = cache.readQuery({
    query: RATING_QUERY_HASURA,
    variables: { id: `${ratingId}` },
  });
  const vineyard = prev.rating_rating_by_pk.vineyard_vineyard;

  // add vine
  const activeRowIndex = vineyard.vineyard_rows.findIndex(
    (r) => r.id === activeRow.id
  );

  const vines = vineyard.vineyard_rows[activeRowIndex].vineyard_vines;
  let newVineIndex;

  if (result.number <= vines[vines.length - 1].number) {
    // not new last vine
    newVineIndex = vines.findIndex((v) => v.number === result.number);
  } else {
    // new last vine
    newVineIndex = vines.length;
  }

  const newVine = {
    __typename: "vineyard_vine",
    location: {
      type: "Point",
      coordinates: [result.location.longitude, result.location.latitude],
    },
    id: parseInt(result.id),
    number: result.number,
  };

  vines.splice(newVineIndex, 0, newVine);

  for (let index = 0; index < vines.length; index++) {
    const vine = vines[index];
    if (index > newVineIndex) {
      vine.number = vine.number += 1;
    }
  }

  const data = { ...prev };
  data.rating_rating_by_pk.vineyard_vineyard = vineyard;

  const vineRatings = data.rating_rating_by_pk.rating_vineratings;
  const vineRatingsInRow = vineRatings
    .filter((v) => v.vineyard_vine)
    .filter(
      (vineRating) => vineRating.vineyard_vine.vineyard_row.id === activeRow.id
    );

  // update the vine ratings...
  for (const vineRating of vineRatingsInRow) {
    const vineyard_vine_id = vineRating.vineyard_vine.id;
    const updatedVine = vines.find((vine) => vine.id === vineyard_vine_id);
    vineRating.vineyard_vine = {
      ...updatedVine,
      vineyard_row: {
        id: activeRow.id,
        __typename: "vineyard_row",
        number: activeRow.number,
      },
    };
  }

  data.rating_rating_by_pk.rating_vineratings = vineRatings;

  // write data to cache
  cache.writeQuery({
    query: RATING_QUERY_HASURA,
    variables: { id: `${ratingId}` },
    data,
  });

  const newActiveRow =
    data.rating_rating_by_pk.vineyard_vineyard.vineyard_rows[activeRowIndex];
  initializeVineRatingMapping(vineRatings);
  setActiveRow(newActiveRow);

  setActiveVine({ ...newVine });
};

/**
 *
 * @param {*} cache
 * @param {*} mutationResult
 * @param {*} ratingId
 * @param {*} setActiveVine
 */
const updateCacheAfterEditing = (
  cache,
  ratingId,
  setActiveVine,
  activeVine,
  activeRow,
  setActiveRow,
  newCoords
) => {
  const prev = cache.readQuery({
    query: RATING_QUERY_HASURA,
    variables: { id: `${ratingId}` },
  });
  const rows = prev.rating_rating_by_pk.vineyard_vineyard.vineyard_rows;

  // find and update vine
  const activeRowIndex = rows.findIndex((r) => r.id === activeRow.id);
  const vineIndex = rows[activeRowIndex].vineyard_vines.findIndex(
    (v) => v.number === activeVine.number
  );
  const vine = rows[activeRowIndex].vineyard_vines[vineIndex];
  vine.location.coordinates = newCoords;

  setActiveVine(vine);

  // this triggers the rerendering of the map
  activeRow.vineyard_vines[vineIndex] = vine;
  setActiveRow(activeRow);
};

const AddOrEditModal = ({ open, action, handleClose, ratingId }) => {
  const [movingVine, setMovingVine] = useState(null); // the vine that's edited or added
  const [loading, setLoading] = useState(false);
  const [addPosition, setAddPosition] = useState("after"); // or before...
  const { activeRow, setActiveRow, activeVine, setActiveVine } =
    useActiveRating();
  const { initializeVineRatingMapping } = useVineRatingMapping();
  const online = useOnlineStatus();

  const [createVineOnline] = useMutation(CREATE_VINE_ONLINE, {
    update: (proxy, mutationResult) => {
      const vine = mutationResult.data.createVines.result.vines[0];
      const result = {
        id: vine.id,
        number: vine.number,
        location: {
          longitude: movingVine.geometry.coordinates[0],
          latitude: movingVine.geometry.coordinates[1],
        },
      };
      updateCacheAfterCreating(
        proxy,
        ratingId,
        setActiveVine,
        activeRow,
        setActiveRow,
        initializeVineRatingMapping,
        result
      );
    },
    onCompleted: () => {
      setLoading(false);
      closeModal();
    },
  });

  const [createVineOffline] = useMutation(CREATE_VINE_OFFLINE, {
    update: (proxy, mutationResult) => {
      const response = mutationResult.data.createVineOffline;
      const result = {
        id: response.id,
        number: response.newVineNumber,
        location: response.location,
      };

      delete result.location.__typename;
      updateCacheAfterCreating(
        proxy,
        ratingId,
        setActiveVine,
        activeRow,
        setActiveRow,
        initializeVineRatingMapping,
        result
      );
    },
    onCompleted: () => {
      setLoading(false);
      closeModal();
    },
  });

  const [editVineOnline] = useMutation(EDIT_VINE_ONLINE_HASURA, {
    update: (proxy, mutationResult) => {
      // make sure the vineyard is rerendered...
      const newCoords =
        mutationResult.data.update_vineyard_vine_by_pk.location.coordinates;
      updateCacheAfterEditing(
        proxy,
        ratingId,
        setActiveVine,
        activeVine,
        activeRow,
        setActiveRow,
        newCoords
      );
    },
    onCompleted: () => {
      setLoading(false);
      closeModal();
    },
  });

  const [editVineOffline] = useMutation(EDIT_VINE_OFFLINE, {
    update: (proxy, mutationResult) => {
      updateCacheAfterEditing(
        proxy,
        ratingId,
        setActiveVine,
        activeVine,
        activeRow,
        setActiveRow,
        movingVine.geometry.coordinates
      );
    },
    onCompleted: () => {
      setLoading(false);
      closeModal();
    },
  });

  useEffect(() => {
    if (open) {
      //   console.log(activeVine.location);
      const coordinates = activeVine.location.coordinates;
      setMovingVine(
        point(coordinates, {
          circleColor: "yellow",
          circleRadius: 8,
          lineColor: "transparent",
        })
      );
    }
  }, [activeVine, setMovingVine, open]);

  /**
   *
   * @param {number} direction 0, 90, 180, 270
   */
  const moveThatVine = (direction) => {
    const distance = 0.0001; // kilometers == 10 cm
    const options = {
      units: "kilometers",
    };
    const newPoint = transformTranslate(
      movingVine,
      distance,
      direction,
      options
    );
    setMovingVine(newPoint);
  };

  const saveEditVine = () => {
    setLoading(true);
    const vine = {
      vineNumber: activeVine.number,
      rowId: activeRow.id,
      location: {
        longitude: movingVine.geometry.coordinates[0],
        latitude: movingVine.geometry.coordinates[1],
      },
    };
    if (online) {
      const input = {
        location: {
          type: "Point",
          coordinates: movingVine.geometry.coordinates,
        },
      };
      const pk_columns = { id: activeVine.id };
      editVineOnline({
        variables: { pk_columns, input },
      });
    } else {
      editVineOffline({
        variables: {
          vineId: activeVine.id,
          vineNumber: vine.vineNumber,
          rowId: vine.rowId,
          longitude: vine.location.longitude,
          latitude: vine.location.latitude,
        },
      });
    }
  };

  const saveCreateVine = () => {
    setLoading(true);

    const newVineNumber =
      addPosition === "after" ? activeVine.number + 1 : activeVine.number;

    const newVine = {
      newVineNumber,
      rowId: activeRow.id,
      location: {
        longitude: movingVine.geometry.coordinates[0],
        latitude: movingVine.geometry.coordinates[1],
      },
    };
    if (online) {
      createVineOnline({ variables: { input: { vines: [newVine] } } });
    } else {
      createVineOffline({
        variables: {
          newVineNumber,
          rowId: activeRow.id,
          longitude: newVine.location.longitude,
          latitude: newVine.location.latitude,
        },
      });
    }
  };

  const handleSave = () => {
    if (action === "edit") {
      saveEditVine();
      return;
    }
    if (action === "add") {
      saveCreateVine();
      return;
    }
  };

  const closeModal = () => {
    setMovingVine(null);
    handleClose();
  };

  let header;
  if (action === "edit") {
    header = `${action} vine ${activeVine.number} of row ${activeRow.number}`;
  } else if (action === "add") {
    header = `${action} new vine after vine ${activeVine.number} in row ${activeRow.number}`;
  }

  return (
    <Modal open={open} onClose={closeModal}>
      <Modal.Header>{header}</Modal.Header>
      <Modal.Content>
        <Wrapper>
          {action && movingVine ? (
            <Map
              center={activeVine.location.coordinates}
              movingVine={movingVine}
              action={action}
              activeRow={activeRow}
              ratingId={ratingId}
            />
          ) : null}
          <MoveButtons onMove={moveThatVine} />
          {action === "add" && (
            <BeforeAfterSelector
              handleChange={setAddPosition}
              addPosition={addPosition}
            />
          )}
        </Wrapper>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={closeModal}>cancel</Button>
        <Button color="green" onClick={handleSave} loading={loading}>
          save
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

const propTypes = {
  action: PropTypes.string,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};
AddOrEditModal.propTypes = propTypes;

export default AddOrEditModal;
