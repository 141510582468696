import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Source, Layer } from "react-mapbox-gl";
import { useQuery } from "@apollo/react-hooks";
import { multiPoint } from "@turf/helpers";
import bbox from "@turf/bbox";
import gql from "graphql-tag";

import { REST_API_URI } from "./../../constants";

export const RATING_QUERY = gql`
  query ratingAndVineyard($id: String!) {
    rating_rating_by_pk(id: $id) {
      id
      name
      vineyard_vineyard {
        name
        flik_number
        geotiff_id
        vineyard_rows(order_by: { number: asc }) {
          id
          number
          vineyard_vines(order_by: { number: asc }) {
            id
            location
            number
          }
        }
      }
    }
  }
`;

const propTypes = {
  ratingId: PropTypes.number.isRequired,
};

const RasterLayer = React.memo(({ ratingId }) => {
  const { data } = useQuery(RATING_QUERY, { variables: { id: `${ratingId}` } });
  const [rasterSourceOptions, setRasterSourceOptions] = useState(null);

  useEffect(() => {
    if (
      data &&
      data.rating_rating_by_pk &&
      data.rating_rating_by_pk.geotiff_id &&
      rasterSourceOptions === null
    ) {
      const coords = [];
      const vineyard = data.rating_rating_by_pk.vineyard_vineyard;
      const rows = vineyard.vineyard_rows;
      for (const row of rows) {
        const firstVine = row.vineyard_vines[0];
        const lastVine = row.vineyard_vines[row.vineyard_vines.length - 1];
        coords.push(firstVine.location.coordinates);
        coords.push(lastVine.location.coordinates);
      }
      const theJson = multiPoint(coords);

      const theBox = bbox(theJson);

      const options = {
        type: "raster",
        tiles: [`${REST_API_URI}/tiles/${vineyard.flikNumber}/{z}/{x}/{y}.png`],
        tileSize: 256,
        scheme: "tms",
        bounds: theBox,
      };

      setRasterSourceOptions(options);
    }
  }, [data, rasterSourceOptions]);

  useEffect(() => {});

  if (rasterSourceOptions) {
    return (
      <React.Fragment>
        <Source id="source_id" tileJsonSource={rasterSourceOptions} />
        <Layer
          // before="vineyard"
          type="raster"
          sourceId="source_id"
          minZoom={18}
          maxZoom={23}
        />
      </React.Fragment>
    );
  }
  return null;
});

RasterLayer.propTypes = propTypes;

export default RasterLayer;
