import * as React from "react";
import styled from "styled-components";
import { Loader } from "semantic-ui-react";

import { useActiveRating } from "./../../../context/activeRating";
import { useVineRatingMapping } from "./../../../context/vineRatingMapping";
import RatingFormButtons from "../../../components/RatingFormButtons";

import FieldMap from "./FieldMap";

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  overflow: hidden;
`;

const FormWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
`;

const MapWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  background-color: #e6e4e0;
`;

export default function RatingFormAndMapPolygons() {
  const { polygonRatingMapping, setPolygonRatingMapping } =
    useVineRatingMapping();
  const { activeRating, activeTrait, setActivePolygon, loading } = useActiveRating();

  function handleAfterRated(_vineNumber, vineRating, _incrementedActiveVine) {
    // // find next active polygon
    // const polygons =
    //   activeRating.rating_rating_by_pk.vineyard_vineyard.vineyard_polygons;
    // const nextPolygon = polygons.find(
    //   (p) => p.number > vineRating.vineyard_polygon.number
    // );
    // if (nextPolygon) {
    //   setActivePolygon(nextPolygon);
    // }
    // update the mapping
    setPolygonRatingMapping({
      ...polygonRatingMapping,
      [`${activeTrait.trait_trait.id}-${vineRating.vineyard_polygon.id}`]: vineRating,
    });
  }
  return (
    <Wrapper>
      <FormWrapper>
        {(!activeRating || !activeTrait) ? (
          <Loader active />
        ) : (
          <RatingFormButtons handleAfterVineRated={handleAfterRated} />
        )}
      </FormWrapper>
      <MapWrapper>{loading ? <Loader active /> : <FieldMap />}</MapWrapper>
    </Wrapper>
  );
}
