import React from "react";
import PropTypes from "prop-types";
import { Loader } from "semantic-ui-react";
import styled from "styled-components";

const ButtonsList = styled.div`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-row-gap: 1rem;
  grid-column-gap: 1rem;
  overflow-y: auto;
`;

const ButtonWrapper = styled.div`
  position: relative;
  border-radius: 4px;
  :before {
    content: "";
    display: block;
    padding-top: 100%;
  }
  > button {
    cursor: pointer;
    background-color: ${(props) => props.bg} !important;
    border: 0;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: black;
    font-size: ${(props) => (props.preview ? 0.75 : 1.5)}rem;
  }
`;

const RatingPad = ({ traitTiers, handleClick, disabled, loading, preview }) => {
  return (
    <ButtonsList>
      {traitTiers.map((t) => {
        const isLoading = loading === t.id;
        return (
          <ButtonWrapper
            key={`button-${t.id}`}
            bg={isLoading ? "lightgrey" : t.color}
            preview={preview}
          >
            <button onClick={() => handleClick(t)} disabled={disabled}>
              {isLoading ? <Loader active /> : <span>{t.name}</span>}
            </button>
          </ButtonWrapper>
        );
      })}
    </ButtonsList>
  );
};

RatingPad.propTypes = {
  traitTiers: PropTypes.array.isRequired,
  handleClick: PropTypes.func,
  loading: PropTypes.number,
  disabled: PropTypes.bool,
  preview: PropTypes.bool,
};

export default RatingPad;
