import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Button, Modal, Popup, Icon } from "semantic-ui-react";
import useOnlineStatus from "@rehooks/online-status";

import { REST_API_URI, JWT_TOKEN_KEY } from "../../../constants";

import Camera from "./Camera";

const propTypes = {
  vine: PropTypes.object.isRequired,
  activeRow: PropTypes.object.isRequired,
  ratingId: PropTypes.number.isRequired,
};

const CameraWrapper = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
`;

const CameraModal = ({ vine, activeRow, ratingId }) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [imgSrc, setImgSrc] = React.useState(null);
  const online = useOnlineStatus();

  const uploadImage = async (image) => {
    setLoading(true);
    const formData = new FormData();
    formData.append("image", image);
    formData.append("vine", vine.id);
    formData.append("rating", ratingId);
    const url = `${REST_API_URI}/images/`;
    const token = localStorage.getItem(JWT_TOKEN_KEY);
    try {
      await fetch(url, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      });
      setLoading(true);
    } catch (error) {
      console.log(error);
      setLoading(true);
    }
  };

  const handleCaptureButton = async () => {
    if (imgSrc) {
      const blob = await (await fetch(imgSrc)).blob();
      const filename = `${new Date().toISOString()}.jpg`;
      const image = new File([blob], filename);
      uploadImage(image);
      setOpen(false);
      setImgSrc(null);
    }
  };

  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      size="large"
      trigger={
        <Popup
          content="Take picture of vine"
          trigger={
            <Button onClick={() => setOpen(true)} icon disabled={!online}>
              <Icon name="camera" />
            </Button>
          }
        />
      }
    >
      <Modal.Header>Take picture for vine {vine.number}</Modal.Header>
      <Modal.Content>
        {open ? (
          <CameraWrapper>
            {imgSrc === null ? (
              <Camera handleImage={(image) => setImgSrc(image)} />
            ) : null}
            {imgSrc !== null ? <img src={imgSrc} alt="alt" /> : null}
          </CameraWrapper>
        ) : null}
      </Modal.Content>
      <Modal.Actions>
        <Button
          disabled={loading}
          onClick={() => {
            setImgSrc(null);
            setOpen(false);
          }}
        >
          cancel
        </Button>
        {imgSrc ? (
          <Button onClick={() => setImgSrc(null)} disabled={loading}>
            retry
          </Button>
        ) : null}
        <Button
          color="green"
          disabled={loading || !imgSrc}
          onClick={handleCaptureButton}
          loading={loading}
        >
          use picture
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

CameraModal.propTypes = propTypes;

export default CameraModal;
