import React from "react";

import HeaderWrapper from "./../../components/HeaderWrapper";
import BodyWrapper from "./../../components/BodyWrapper";

import NewTraitModal from "./NewTraitModal";
import TraitsTable from "./TraitsTable";

const TraitsView = () => {
  return (
    <React.Fragment>
      <HeaderWrapper header="Traits" action={<NewTraitModal />} />
      <BodyWrapper>
        <TraitsTable />
      </BodyWrapper>
    </React.Fragment>
  );
};

export default TraitsView;
