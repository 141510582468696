import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import "semantic-ui-css/semantic.min.css";
import localForage from "localforage";

import { ApolloLink } from "apollo-link";
import ApolloClient from "apollo-client";
import { ApolloProvider } from "@apollo/react-hooks";
import { InMemoryCache } from "apollo-cache-inmemory";
import { createUploadLink } from "apollo-upload-client";
import { persistCache } from "apollo-cache-persist";
import { setContext } from "apollo-link-context";
import * as Sentry from "@sentry/browser";

import "./index.css";
import { isAuthenticated } from "./helpers/auth";
import App from "./components/App";
import AppProviders from "./components/AppProviders";
import FullPageLoader from "./components/FullPageLoader";
import * as serviceWorker from "./serviceWorker";

import {
  JWT_TOKEN_KEY,
  CONTENT_CACHED_EVENT,
  NEW_CONTENT_EVENT,
  APOLLO_CACHE_PERSIST_KEY,
  GRAPHQL_URI,
} from "./constants";

import { resolvers } from "./state";

if (process.env.NODE_ENV === "production") {
  Sentry.init({
    dsn: "https://a0039c2b89a8423b9da6e1b3b0248323@sentry.io/1500778",
  });
}

const Root = () => {
  const [client, setClient] = useState(null);

  useEffect(() => {
    const getClient = async () => {
      const uploadLink = createUploadLink({
        uri: `${GRAPHQL_URI}/v1/graphql`,
      });

      const authLink = setContext((_, { headers }) => {
        // get the authentication token from local storage if it exists
        const token = localStorage.getItem(JWT_TOKEN_KEY);
        // return the headers to the context so httpLink can read them
        return {
          headers: {
            ...headers,
            Authorization: token ? `Bearer ${token}` : "",
            "X-Hasura-Role": "user",
            // "x-hasura-admin-secret": "myadminsecretkey",
          },
        };
      });

      const cache = new InMemoryCache();

      const existingCache = await localForage.getItem(APOLLO_CACHE_PERSIST_KEY);

      // only initialize the cache if it has not been persisted yet.
      if (!existingCache) {
        cache.writeData({
          data: {
            isAuthenticated: isAuthenticated(),
            offlineRatings: [],
            offlineCreateVines: [],
            offlineEditVines: [],
            offlineDeleteVines: [],
          },
        });
      }

      // // await before instantiating ApolloClient, else queries might run before the cache is persisted
      await persistCache({
        cache,
        storage: localForage,
        debug: process.env.NODE_ENV !== "production",
        maxSize: false,
        key: APOLLO_CACHE_PERSIST_KEY,
      });

      const client = new ApolloClient({
        link: ApolloLink.from([authLink, uploadLink]),
        cache,
        resolvers,
      });

      // some weird hack?!?
      setTimeout(() => setClient(client), 100);
    };
    getClient();
  }, []);

  if (!client) {
    return <FullPageLoader />;
  }

  return (
    <ApolloProvider client={client}>
      <AppProviders>
        <App />
      </AppProviders>
    </ApolloProvider>
  );
};

ReactDOM.render(<Root />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA

let contentCached, newContent;
try {
  contentCached = new Event(CONTENT_CACHED_EVENT);
  newContent = new Event(NEW_CONTENT_EVENT);
} catch (error) {
  contentCached = null;
  newContent = null;
}

const swConfig = {
  onUpdate: () => {
    console.log("on update calling");
    if (newContent) {
      window.dispatchEvent(newContent);
    } else {
      console.log("no 'newContent' Event");
    }
  },
  onSuccess: () => {
    if (contentCached) {
      window.dispatchEvent(contentCached);
    }
  },
};

serviceWorker.register(swConfig);
