import React from "react";
import useReactRouter from "use-react-router";
import { Prompt } from "react-router-dom";

import { useActiveRating } from "./../../context/activeRating";
import HeaderWrapper from "./../../components/HeaderWrapper";
import BodyWrapper from "./../../components/BodyWrapper";

import { RATINGS_VIEW } from "./../../constants/routes";

import RatingFormAndMap from "./RatingFormAndMap";
import RatingFormAndMapPolygons from "./RatingFormAndMapPolygons";

const DoRateView = () => {
  const { setActiveRatingId, activeRating, loading } = useActiveRating();
  const { match } = useReactRouter();
  let { id } = match.params;
  id = parseInt(id);

  React.useEffect(() => {
    setActiveRatingId(id);
  }, [id, setActiveRatingId]);

  const geometryType =
    activeRating &&
    activeRating.rating_rating_by_pk &&
    activeRating.rating_rating_by_pk.vineyard_vineyard.geometry_types;

  return (
    <React.Fragment>
      <Prompt
        when={true}
        message="Are you sure you want to leave the rating?"
      />
      <HeaderWrapper backlink={RATINGS_VIEW.pathname} header="Rating" />
      <BodyWrapper>
        {geometryType === "points" && (
          <RatingFormAndMap
            rating={activeRating.rating_rating_by_pk}
            loading={loading}
          />
        )}
        {geometryType === "polygons" && <RatingFormAndMapPolygons />}
      </BodyWrapper>
    </React.Fragment>
  );
};

export default DoRateView;
