import React from "react";
import PropTypes from "prop-types";
import { Dropdown } from "semantic-ui-react";
import { useActiveRating } from "./../../../../../context/activeRating";

const RowPicker = ({ rows }) => {
  const rowOptions = rows.map((row) => ({
    text: `Row ${row.number}`,
    value: row.number,
    key: row.number,
  }));

  const { activeRow, setActiveRow } = useActiveRating();

  return (
    <Dropdown
      fluid
      selection
      options={rowOptions}
      value={activeRow.number}
      onChange={(evt, data) => {
        const theRow = rows.find((r) => r.number === data.value);
        setActiveRow(theRow);
      }}
    />
  );
};

const propTypes = {
  rows: PropTypes.array.isRequired,
};

RowPicker.propTypes = propTypes;

export default RowPicker;
