import React, { useEffect, useState } from "react";
import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";

const USER_QUERY = gql`
  {
    viewer {
      user {
        id
        email
      }
    }
  }
`;

const UserContext = React.createContext();

function UserProvider(props) {
  const [user, setUser] = useState(null);
  const { data } = useQuery(USER_QUERY);

  useEffect(() => {
    if (data && data.viewer) {
      setUser({
        id: data.viewer.user.id,
        email: data.viewer.user.email,
      });
    }
  }, [data]);

  return <UserContext.Provider value={{ user }} {...props} />;
}

const useUser = () => React.useContext(UserContext);

export { UserProvider, useUser };
