import React, { useEffect, useState } from "react";
import Bowser from "bowser";
import { Modal } from "semantic-ui-react";

const BrowserDetector = () => {
  const [state, setState] = useState({ showBrowserModal: false });

  useEffect(() => {
    const tabletBrowsers = ["Safari", "Chrome"];
    const browser = Bowser.getParser(window.navigator.userAgent);
    const platform = browser.getPlatformType();
    const browserName = browser.getBrowserName();

    if (platform === "tablet" && !tabletBrowsers.includes(browserName)) {
      let message =
        "Your using an unsupported device / browser combination. Your safest bet is Google Chrome.";
      if (browser.getOSName() === "Android") {
        message = "Please use the Google Chrome browser on an Android tablet.";
      }
      if (browser.getOSName() === "iOS") {
        message = "Please use the Safari browser on an iOS tablet.";
      }
      setState(state => ({
        ...state,
        showBrowserModal: true,
        message
      }));
    }
  }, []);

  return (
    <Modal
      open={state.showBrowserModal}
      header="Unsupported browser!"
      content={state.message}
      actions={["Got it."]}
      onClose={() => setState(state => ({ ...state, showBrowserModal: false }))}
    />
  );
};

export default BrowserDetector;
