import React from "react";

import HeaderWrapper from "./../../components/HeaderWrapper";
import BodyWrapper from "./../../components/BodyWrapper";
import NewRatingModal from "./NewRatingModal";
import RatingsTable from "./RatingsTable";

const RatingsView = () => {
  return (
    <React.Fragment>
      <HeaderWrapper header="Ratings" action={<NewRatingModal />} />
      <BodyWrapper>
        <RatingsTable />
      </BodyWrapper>
    </React.Fragment>
  );
};

export default RatingsView;
