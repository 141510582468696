import React, { useState, useEffect, useRef, useCallback } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Loader } from "semantic-ui-react";

import Webcam from "react-webcam";

const propTypes = {
  handleImage: PropTypes.func.isRequired,
};

const CameraWrapper = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
`;

const LoadingWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

const WIDTH = 75;

const CaptureButtonWrapper = styled.div`
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-${WIDTH / 2}px);
  width: ${WIDTH}px;
  height: ${WIDTH}px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 4px solid white;
`;

const CaptureButton = styled.button`
  background: white;
  border: 0;
  width: ${WIDTH * 0.8}px;
  height: ${WIDTH * 0.8}px;
  border-radius: 50%;
  cursor: pointer;
`;

const CameraModal = ({ handleImage }) => {
  const webcamRef = useRef(null);
  const [loading, setLoading] = useState(true);
  const [stream, setStream] = useState(null);

  console.log("loading", loading);

  const videoConstraints =
    process.env.NODE_ENV === "development"
      ? { facingMode: "user" }
      : {
          facingMode: { exact: "environment" },
        };

  const capture = useCallback(() => {
    if (webcamRef.current) {
      const imageSrc = webcamRef.current.getScreenshot();
      handleImage(imageSrc);
    }
  }, [webcamRef, handleImage]);

  useEffect(() => {
    return () => {
      if (stream) {
        stream.getVideoTracks().map((track) => track.stop());
      }
    };
  }, [stream]);

  return (
    <CameraWrapper>
      {loading ? (
        <LoadingWrapper>
          <Loader active />
        </LoadingWrapper>
      ) : null}
      <Webcam
        audio={false}
        screenshotFormat="image/jpeg"
        ref={webcamRef}
        onUserMedia={(media) => {
          setStream(media);
          setLoading(false);
        }}
        videoConstraints={videoConstraints}
      />
      <CaptureButtonWrapper>
        <CaptureButton onClick={capture} />
      </CaptureButtonWrapper>
    </CameraWrapper>
  );
};

CameraModal.propTypes = propTypes;

export default CameraModal;
