import * as React from "react";
import styled from "styled-components";
import { useMeasure } from "react-use";
import ReactMapboxGl from "react-mapbox-gl";
import center from "@turf/center";

import { useActiveRating } from "./../../../../context/activeRating";
import PolygonGeojson from "./PolygonGeojson";

const Map = ReactMapboxGl({
  accessToken:
    "pk.eyJ1IjoiY3JlaW1lcnMiLCJhIjoiY2p4cHZjZDdhMGs3ZzNvcDlxdXhzNzJwbyJ9.bz6je21hT4R6nL6LNT4dKQ",
});

const Wrapper = styled.div`
  height: 100%;
  position: relative;
`;

export default function FieldMap() {
  const [ref, { height }] = useMeasure();
  const [zoom, setZoom] = React.useState(20);

  const { activePolygon } = useActiveRating();

  const mapCenter = React.useMemo(() => {
    if (activePolygon) {
      const c = center({ type: "Feature", geometry: activePolygon.geometry });
      return c.geometry.coordinates
    }
    return null
  }, [activePolygon]);

  return (
    <Wrapper ref={ref}>
      {height && mapCenter && (
        <Map
          style="mapbox://styles/mapbox/streets-v9" // eslint-disable-line react/style-prop-object
          containerStyle={{
            height: `${height}px`,
          }}
          center={mapCenter}
          zoom={[zoom]}
          onZoomEnd={(map, e) => {
            setZoom(map.transform._zoom);
            // HACK
            map.transform.maxZoom = 23;
          }}
        >
          {/* <RasterLayer ratingId={ratingId} /> */}
          {/* <UserLocation /> */}
          {/* <VineyardGeojson activeRow={activeRow} ratingId={ratingId} /> */}
          <PolygonGeojson />
        </Map>
      )}
    </Wrapper>
  );
}
