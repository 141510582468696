import DoRateView from "./../views/DoRate";
import IndexView from "./../views/Index";
import LoginView from "./../views/Login";
import SettingsView from "./../views/Settings";
import RatingsView from "./../views/Ratings";
import TraitsView from "../views/Traits";
import TraitsDetailView from "../views/TraitsDetail";

export const INDEX_VIEW = {
  private: false,
  pathname: "/",
  title: "Index",
  component: IndexView
};
export const LOGIN_VIEW = {
  private: false,
  pathname: "/login",
  title: "Login",
  component: LoginView
};

export const RATINGS_VIEW = {
  private: true,
  pathname: "/ratings",
  title: "Ratings",
  component: RatingsView,
  icon: "star"
};

export const DO_RATE_VIEW = {
  private: true,
  pathname: "/ratings/:id/rate",
  title: "Ratings",
  component: DoRateView,
  icon: "star"
};

export const TRAITS_VIEW = {
  private: true,
  pathname: "/traits",
  title: "Traits",
  component: TraitsView,
  icon: "search"
};

export const TRAITS_DETAIL_VIEW = {
  private: true,
  pathname: "/traits/:id",
  title: "Trait Details",
  component: TraitsDetailView
};

export const SETTINGS_VIEW = {
  private: true,
  pathname: "/settings",
  title: "Settings",
  component: SettingsView,
  icon: "settings"
};

export const ROUTES = [
  INDEX_VIEW,
  LOGIN_VIEW,
  RATINGS_VIEW,
  DO_RATE_VIEW,
  TRAITS_VIEW,
  TRAITS_DETAIL_VIEW,
  SETTINGS_VIEW
];
